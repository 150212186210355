import { createContext, useContext, useState } from 'react';

const FilterContext = createContext();

export const FilterProvider = ({ children }) => {
  const [filterText, setFilterText] = useState('');
  const [filterDate, setFilterDate] = useState('');
  const [filterShow, setFilterShow] = useState([]);

  return (
    <FilterContext.Provider value={{ filterText, setFilterText, filterDate, setFilterDate, filterShow, setFilterShow }}>
      {children}
    </FilterContext.Provider>
  );
};

export const useFilter = () => {
  return useContext(FilterContext);
};