import { useUser } from "../provider/UserProvider";
import Login from "./Login";
import Registration from "./Registration";

const AuthModal = () => {
    const { auth } = useUser();
    return (
        <div className="w-full h-screen fixed bg-[#191F28]/60 top-0 left-0 flex justify-center items-center z-[100]">
            {auth === 1 && 
                <Login />
            }
            
            {auth === 2 && 
                <Registration />
            }
        </div>
    );
}
 
export default AuthModal;