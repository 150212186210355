import { IoMdClose } from "react-icons/io";
import { useUser } from "../../provider/UserProvider";
import { useState } from "react";


const ForgotModal = () => {
    const { forgotPassword, setForgotPassword } = useUser();
    const [error, setError] = useState(false);
    const [formData, setFormData] = useState({
        email: ''
    });
    const [loading, setLoadin] = useState(false);

    const handleChange = (e) => {
        setError(false);
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const isButtonDisabled = !formData.email;

    const ConfirmationEmail = async (e) => {
        e.preventDefault();
        setLoadin(true);

        try {
            const response = await fetch('https://api.betterartist.ai/api/core/user/reset-password-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });
            if (response.ok) {
                const data = await response.json();
                console.log(data);
                setLoadin(false)
                return setForgotPassword(2);
            }
            setLoadin(false);
            return setError(true);
        } catch (error) {
            setLoadin(false);
            return setError(true);
        }
    };
    const ResendEmail = async (e) => {
        e.preventDefault();
        setLoadin(true);

        try {
            const response = await fetch('https://api.betterartist.ai/api/core/user/reset-password-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });
            if (response.ok) {
                const data = await response.json();
                console.log(data);
                setLoadin(false)
                return setForgotPassword(3);
            }
            setLoadin(false);
            return setError(true);
        } catch (error) {
            setLoadin(false);
            return setError(true);
        }
    };

    return (
        <div className="w-full h-screen fixed bg-[#191F28]/60 top-0 left-0 flex justify-center items-center z-[100]">
            <div className="p-4 bg-[#1E293B] w-full md:max-w-[440px] h-screen md:h-auto md:p-10 md:rounded-[14px]">
                {forgotPassword === 1 &&
                    <form onSubmit={ConfirmationEmail} className="flex flex-col justify-between items-start" style={{'height': '-webkit-fill-available'}}>
                        <div className="w-full">
                            <div className="flex w-full justify-start items-center gap-4 text-white md:flex-row-reverse md:justify-between">
                                <button className="text-[24px]" onClick={() => {setForgotPassword(0);}}>
                                    <IoMdClose />
                                </button>
                                <div className="font-extrabold text-[28px]">
                                    Confirmation Email
                                </div>
                            </div>
                            <div className="w-full bg-[#0F172A] rounded-lg relative input-style mt-10">
                                <input 
                                    className={`rounded-lg w-full bg-transparent px-[16px] relative z-10 text-[16px] leading-6 text-white ${formData.email ? 'pt-6 pb-2' : 'pt-4 pb-4'}`} 
                                    style={{'borderColor': error && '#F87171'}} 
                                    type="text" 
                                    name="email" 
                                    value={formData.email} 
                                    onChange={handleChange} 
                                    required 
                                />
                                <span className={`absolute z-0 left-4 text-[#94A3B8] block ${formData.email ? 'text-[12px] top-2' : 'text-[14px] top-4'}`}>Enter email</span>
                            </div>
                            {error && 
                                <div className="text-[14px] text-[#F87171]">
                                    Email is Invalid
                                </div>
                            }
                        </div>
                        <div className="flex flex-col gap-2 w-full">
                            <button 
                                type="submit" 
                                disabled={isButtonDisabled || loading} 
                                className={`mt-6 text-[14px] leading-[28px] w-full py-2 px-4 bg-green-300 rounded-[8px] text-gray-900 font-semibold ${isButtonDisabled && 'opacity-30 cursor-not-allowed'}`}
                            >
                                {loading ? 
                                    <div className="flex justify-center items-center">
                                        <div className="w-[28px] h-[28px] rounded-full animate-spin border-2 border-solid border-gray-900 border-t-transparent"></div>
                                    </div>
                                    :
                                    'Send Confirmation Email'
                                }
                            </button>
                        </div>

                    </form>
                }

                {forgotPassword === 2 &&
                    <form onSubmit={ResendEmail} className="flex flex-col justify-between items-start pb-4" style={{'height': '-webkit-fill-available'}}>
                        <div className="w-full">
                            <div className="flex w-full justify-start items-center gap-4 text-white md:flex-row-reverse md:justify-between">
                                <button className="text-[24px]" onClick={() => {setForgotPassword(0);}}>
                                    <IoMdClose />
                                </button>
                                <div className="font-extrabold text-[28px]">
                                    
                                </div>
                            </div>
                            <div className="flex justify-center items-center gap-6 flex-col mt-6">
                            <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 120 120" fill="none">
                                <rect width="120" height="120" rx="60" fill="#334155"/>
                                <path d="M90 42C90 38.7 87.3 36 84 36H36C32.7 36 30 38.7 30 42V78C30 81.3 32.7 84 36 84H84C87.3 84 90 81.3 90 78V42ZM84 42L60 56.97L36 42H84ZM84 78H36V48L60 63L84 48V78Z" fill="#6EE7B7"/>
                            </svg>
                            <div className="text-[28px] leading-[34px] text-[#F8FAFC] font-extrabold">
                            Please check your email.
                            </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-2 w-full">
                            <button 
                                type="submit" 
                                disabled={isButtonDisabled || loading} 
                                className={`mt-6 text-[14px] leading-[28px] w-full rounded-[8px] text-[#F8FAFC] font-semibold ${isButtonDisabled && 'opacity-30 cursor-not-allowed'}`}
                            >
                                {loading ? 
                                    <div className="flex justify-center items-center">
                                        <div className="w-[28px] h-[28px] rounded-full animate-spin border-2 border-solid border-[#F8FAFC] border-t-transparent"></div>
                                    </div>
                                    :
                                    <div>
                                        Didn’t receive an email? <span className="text-[#6EE7B7]">Resend.</span>
                                    </div>
                                }
                            </button>
                        </div>

                    </form>
                }

                {forgotPassword === 3 &&
                    <form onSubmit={ResendEmail} className="flex flex-col justify-between items-start pb-4" style={{'height': '-webkit-fill-available'}}>
                        <div className="w-full">
                            <div className="flex w-full justify-start items-center gap-4 text-white md:flex-row-reverse md:justify-between">
                                <button className="text-[24px]" onClick={() => {setForgotPassword(0);}}>
                                    <IoMdClose />
                                </button>
                                <div className="font-extrabold text-[28px]">
                                    
                                </div>
                            </div>
                            <div className="flex justify-center items-center gap-6 flex-col mt-6">
                            <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 120 120" fill="none">
                                <rect width="120" height="120" rx="60" fill="#334155"/>
                                <path d="M90 42C90 38.7 87.3 36 84 36H36C32.7 36 30 38.7 30 42V78C30 81.3 32.7 84 36 84H84C87.3 84 90 81.3 90 78V42ZM84 42L60 56.97L36 42H84ZM84 78H36V48L60 63L84 48V78Z" fill="#6EE7B7"/>
                            </svg>
                            <div className="text-[28px] leading-[34px] text-[#F8FAFC] font-extrabold">
                                Email Resent.
                            </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-2 w-full">
                            <button 
                                type="submit" 
                                disabled={isButtonDisabled || loading} 
                                className={`mt-6 text-[14px] leading-[28px] w-full rounded-[8px] text-[#F8FAFC] font-semibold ${isButtonDisabled && 'opacity-30 cursor-not-allowed'}`}
                            >
                                {loading ? 
                                    <div className="flex justify-center items-center">
                                        <div className="w-[28px] h-[28px] rounded-full animate-spin border-2 border-solid border-[#F8FAFC] border-t-transparent"></div>
                                    </div>
                                    :
                                    <div>
                                        Didn’t receive an email? <span className="text-[#6EE7B7]">Resend.</span>
                                    </div>
                                }
                            </button>
                        </div>

                    </form>
                }
            </div>
        </div>
    );
}
 
export default ForgotModal;