import { useEffect, useState } from "react";
import { useUser } from "../../../provider/UserProvider";
import { useFilter } from "../../../provider/filterProvider";
import moment from 'moment';
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";

const History = () => {
    const { userAccess } = useUser();
    const [pageLoading, setPageLoading] = useState(true);
    const [savedHistory, setSavedHistory] = useState([]);
    const { filterText, filterDate, setFilterShow } = useFilter();
    const [filteredArray, setFilteredArray] = useState([]);
    const [today, setToday] = useState([]);
    const [yesterday, setYesterday] = useState([]);
    const [lastWeek, setLastWeek] = useState([]);
    const [lastMonth, setLastMonth] = useState([]);

    const historyImage = async () => {
        setPageLoading(true);
        try {
            const response = await fetch('https://api.betterartist.ai/api/core/art/history', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userAccess}`,
                }
            });
        
            if (response.ok) {
                const data = await response.json();
                setSavedHistory(data);
                setPageLoading(false);
            }
        } catch (error) {
            setPageLoading(false);
            return null;
        }
    }

    useEffect(() => {
        historyImage();
    }, [userAccess])

    useEffect(() => {
        const filteredArr = savedHistory.filter(item => {
            const isTextMatch = filterText === '' || item.prompt.toLowerCase().includes(filterText.toLowerCase());
            if (filterDate === '') {
                return isTextMatch;
            }
        
            const createdAtDate = new Date(item.created_at);
            const currentDate = new Date();
        
            let isDateMatch = true;
        
            if (filterDate === 'day') {
                const twentyFourHoursAgo = new Date(currentDate - 24 * 60 * 60 * 1000);
                isDateMatch = createdAtDate >= twentyFourHoursAgo;
            } else if (filterDate === 'lastday') {
                const fortyEightHoursAgo = new Date(currentDate - 48 * 60 * 60 * 1000);
                isDateMatch = createdAtDate >= fortyEightHoursAgo;
            } else if (filterDate === 'lastweek') {
                const sevenDaysAgo = new Date(currentDate - 7 * 24 * 60 * 60 * 1000);
                isDateMatch = createdAtDate >= sevenDaysAgo;
            } else if (filterDate === 'lastmonth') {
                const twentyEightDaysAgo = new Date(currentDate - 28 * 24 * 60 * 60 * 1000);
                isDateMatch = createdAtDate >= twentyEightDaysAgo;
            }
    
            return isTextMatch && isDateMatch;
        });
    
        setFilteredArray(filteredArr);
        setFilterShow(filteredArr);
    }, [filterText, filterDate, savedHistory]);

    useEffect(() => {
        const currentDate = moment();

        const todayData = [];
        const yesterdayData = [];
        const lastWeekData = [];
        const lastMonthData = [];

        filteredArray.forEach((item) => {
        const createdAt = moment(item.created_at);
        const diffInDays = currentDate.diff(createdAt, 'days');

        if (diffInDays === 0) {
            todayData.push(item);
        } else if (diffInDays === 1) {
            yesterdayData.push(item);
        } else if (diffInDays > 1 && diffInDays <= 7) {
            lastWeekData.push(item);
        } else if (diffInDays > 7 && diffInDays <= 28) {
            lastMonthData.push(item);
        }
        });

        setToday(todayData);
        setYesterday(yesterdayData);
        setLastWeek(lastWeekData);
        setLastMonth(lastMonthData);
    }, [filteredArray]);

    return (
        <>
            <div className="p-4 pt-0">
                <div className="w-full gap-2 inline-flex md:gap-7">
                    {filteredArray.length !== 0 ?
                        <div className="flex w-full flex-col">
                            {today.length !== 0 &&
                                <div className="w-full flex flex-col gap-2 md:flex-row md:justify-between md:mt-10 md:gap-6">
                                    <div className="text-[14px] leading-5 text-[#F8FAFC] font-bold mb-1 mt-3 md:text-[36px] md:leading-[44px] md:w-[40%] flex gap-4">
                                        Today
                                        <div className="hidden md:block flex-1 h-[1px] bg-[#475569] mt-[21px]"></div>
                                    </div>
                                    <div className="flex flex-col gap-3 md:flex-row md:w-[60%] md:flex-wrap md:gap-6">
                                        {today.map((item, index) => (
                                            <Link to={`/result/${item.uid}`} key={index} className="bg-[rgba(51,65,85,0.50)] w-full p-3 rounded-lg flex gap-4 items-center md:w-[calc(50%-12px)] md:p-6 hover:bg-[#475569] transition-all">
                                                <p className="text-[14px] leading-5 text-[#F8FAFC] font-normal flex-1 line-clamp-2 h-10 md:h-[56px] md:text-[20px] md:leading-[28px] md:font-semibold">{item.prompt}</p>
                                                <div>
                                                    <IoIosArrowForward className="text-[#94A3B8] text-[24px] cursor-pointer md:text-[32px]" />
                                                </div>
                                            </Link>
                                        ))}
                                    </div>
                                </div>
                            }
                            {yesterday.length !== 0 &&
                                <div className="w-full flex flex-col gap-2 md:flex-row md:justify-between md:mt-10 md:gap-6">
                                    <div className="text-[14px] leading-5 text-[#F8FAFC] font-bold mb-1 mt-3 md:text-[36px] md:leading-[44px] md:w-[40%] flex gap-4">
                                        Yesterday
                                        <div className="hidden md:block flex-1 h-[1px] bg-[#475569] mt-[21px]"></div>
                                    </div>
                                    <div className="flex flex-col gap-3 md:flex-row md:w-[60%] md:flex-wrap md:gap-6">
                                        {yesterday.map((item, index) => (
                                            <Link to={`/result/${item.uid}`} key={index} className="bg-[rgba(51,65,85,0.50)] w-full p-3 rounded-lg flex gap-4 items-center md:w-[calc(50%-12px)] md:p-6 hover:bg-[#475569] transition-all">
                                                <p className="text-[14px] leading-5 text-[#F8FAFC] font-normal flex-1 line-clamp-2 h-10 md:h-[56px] md:text-[20px] md:leading-[28px] md:font-semibold">{item.prompt}</p>
                                                <div>
                                                    <IoIosArrowForward className="text-[#94A3B8] text-[24px] cursor-pointer md:text-[32px]" />
                                                </div>
                                            </Link>
                                        ))}
                                    </div>
                                </div>
                            }
                            {lastWeek.length !== 0 &&
                                <div className="w-full flex flex-col gap-2 md:flex-row md:justify-between md:mt-10 md:gap-6">
                                    <div className="text-[14px] leading-5 text-[#F8FAFC] font-bold mb-1 mt-3 md:text-[36px] md:leading-[44px] md:w-[40%] flex gap-4">
                                        Last Week
                                        <div className="hidden md:block flex-1 h-[1px] bg-[#475569] mt-[21px]"></div>
                                    </div>
                                    <div className="flex flex-col gap-3 md:flex-row md:w-[60%] md:flex-wrap md:gap-6">
                                        {lastWeek.map((item, index) => (
                                            <Link to={`/result/${item.uid}`} key={index} className="bg-[rgba(51,65,85,0.50)] w-full p-3 rounded-lg flex gap-4 items-center md:w-[calc(50%-12px)] md:p-6 hover:bg-[#475569] transition-all">
                                                <p className="text-[14px] leading-5 text-[#F8FAFC] font-normal flex-1 line-clamp-2 h-10 md:h-[56px] md:text-[20px] md:leading-[28px] md:font-semibold">{item.prompt}</p>
                                                <div>
                                                    <IoIosArrowForward className="text-[#94A3B8] text-[24px] cursor-pointer md:text-[32px]" />
                                                </div>
                                            </Link>
                                        ))}
                                    </div>
                                </div>
                            }
                            {lastMonth.length !== 0 &&
                                <div className="w-full flex flex-col gap-2 md:flex-row md:justify-between md:mt-10 md:gap-6">
                                    <div className="text-[14px] leading-5 text-[#F8FAFC] font-bold mb-1 mt-3 md:text-[36px] md:leading-[44px] md:w-[40%] flex gap-4">
                                        Last 28 Days
                                        <div className="hidden md:block flex-1 h-[1px] bg-[#475569] mt-[21px]"></div>
                                    </div>
                                    <div className="flex flex-col gap-3 md:flex-row md:w-[60%] md:flex-wrap md:gap-6">
                                        {lastMonth.map((item, index) => (
                                            <Link to={`/result/${item.uid}`} key={index} className="bg-[rgba(51,65,85,0.50)] w-full p-3 rounded-lg flex gap-4 items-center md:w-[calc(50%-12px)] md:p-6 hover:bg-[#475569] transition-all">
                                                <p className="text-[14px] leading-5 text-[#F8FAFC] font-normal flex-1 line-clamp-2 h-10 md:h-[56px] md:text-[20px] md:leading-[28px] md:font-semibold">{item.prompt}</p>
                                                <div>
                                                    <IoIosArrowForward className="text-[#94A3B8] text-[24px] cursor-pointer md:text-[32px]" />
                                                </div>
                                            </Link>
                                        ))}
                                    </div>
                                </div>
                            }
                        </div>
                        :
                        <div className="w-full p-10 flex justify-center items-center">
                            {pageLoading ?
                                <div className="w-[28px] h-[28px] rounded-full animate-spin border-2 border-solid border-green-300 border-t-transparent"></div>
                            :
                                <div className="flex justify-center items-center gap-6 flex-col">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="120" height="121" viewBox="0 0 120 121" fill="none">
                                        <circle cx="60" cy="60.5" r="60" fill="#334155"/>
                                        <path d="M82.5 33H38.5C35.475 33 33.0275 35.475 33.0275 38.5L33 88L44 77H82.5C85.525 77 88 74.525 88 71.5V38.5C88 35.475 85.525 33 82.5 33ZM74.25 66H46.75C45.2375 66 44 64.7625 44 63.25C44 61.7375 45.2375 60.5 46.75 60.5H74.25C75.7625 60.5 77 61.7375 77 63.25C77 64.7625 75.7625 66 74.25 66ZM74.25 57.75H46.75C45.2375 57.75 44 56.5125 44 55C44 53.4875 45.2375 52.25 46.75 52.25H74.25C75.7625 52.25 77 53.4875 77 55C77 56.5125 75.7625 57.75 74.25 57.75ZM74.25 49.5H46.75C45.2375 49.5 44 48.2625 44 46.75C44 45.2375 45.2375 44 46.75 44H74.25C75.7625 44 77 45.2375 77 46.75C77 48.2625 75.7625 49.5 74.25 49.5Z" fill="#0F172A"/>
                                    </svg>
                                    <div className="text-[23px] leading-[28px] font-bold text-[#F8FAFC]">No Prompt History</div>
                                    <div className="text-[14px] md:text-[16px] text-center leading-[25px] font-semibold text-[#F8FAFC]">To get started, generate your first prompt.</div>
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>
        </>
    );
}
 
export default History;