import { createContext, useContext, useState } from 'react';

const GenContext = createContext();

export const GenProvider = ({ children }) => {
  const [genPrompt, setGenPromp] = useState('');
  const [genStyle, setGenStyle] = useState('none');
  const [genSize, setGenSize] = useState('1:1');
  const [genSameUrl, setGenSameUrl] = useState('');
  const [genUpdate, setGenUpdate] = useState(false);

  return (
    <GenContext.Provider value={{ genPrompt, setGenPromp, genStyle, setGenStyle, genSize, setGenSize, genSameUrl, setGenSameUrl, genUpdate, setGenUpdate }}>
      {children}
    </GenContext.Provider>
  );
};

export const useGen = () => {
  return useContext(GenContext);
};