import { Link, useNavigate } from "react-router-dom";
import { useGen } from "../../provider/genProvider";
import { PiLightningFill } from "react-icons/pi";
import { useEffect, useRef, useState } from "react";

import None from "../../assets/image/styleImg/None.png";
import Oil from "../../assets/image/styleImg/Oil.png";
import Drawing from "../../assets/image/styleImg/Drawing.png";
import Cyberpunk from "../../assets/image/styleImg/Cyberpunk.png";
import Wildlife from "../../assets/image/styleImg/Wildlife.png";
import { useUser } from "../../provider/UserProvider";
import { v4 as uuidv4 } from 'uuid';


const InputDashboard = () => {
    const { genPrompt, setGenPromp, genStyle, setGenStyle, genSize, setGenSize, setGenUpdate } = useGen();
    const { userAccess, setAuth, setWs, setUserData } = useUser();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    
    const [sizeButton, setSizeButton] = useState(false);
    const [styleButton, setStyleButton] = useState(false);
    const sizeRef = useRef(null);
    const styleRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (sizeRef.current && !sizeRef.current.contains(event.target)) {
                setSizeButton(false);
            }
        };
    
        if (sizeButton) {
            document.addEventListener('click', handleClickOutside);
        } else {
            document.removeEventListener('click', handleClickOutside);
        }
    
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [sizeButton]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (styleRef.current && !styleRef.current.contains(event.target)) {
                setStyleButton(false);
            }
        };
    
        if (styleButton) {
            document.addEventListener('click', handleClickOutside);
        } else {
            document.removeEventListener('click', handleClickOutside);
        }
    
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [styleButton]);

    const SurpriseMe = async () => {
        setLoading(true);

        try {
            const response = await fetch('https://api.betterartist.ai/api/core/art/prompt', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if (response.ok) {
                const data = await response.json();
                setGenPromp(data.prompt);
                return setLoading(false);
            }
            return null;
        } catch (error) {
            return setLoading(false);
        }
    }

    const ratio = [
        {
            name: 'Landscape',
            size: '4:3',
            width: '16',
            height: '12'
        },
        {
            name: 'Portrait',
            size: '3:4',
            width: '12',
            height: '16'
        },
        {
            name: 'Square',
            size: '1:1',
            width: '16',
            height: '16'
        },
        {
            name: 'Widescreen',
            size: '16:9',
            width: '16',
            height: '9'
        }
    ]

    const style = [
        {
            name: 'none',
            img: None
        },
        {
            name: 'oil',
            img: Oil
        },
        {
            name: 'drawing',
            img: Drawing
        },
        {
            name: 'cyberpunk',
            img: Cyberpunk
        },
        {
            name: 'wildlife',
            img: Wildlife
        }, 
    ]

    async function generateLink() {
        if(userAccess) {
            const userMe = await fetch('https://api.betterartist.ai/api/core/user/me', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${userAccess}`,
                },
            });

            if (!userMe.ok) {
                throw new Error('Помилка при виконанні GET запиту');
            }

                const data = await userMe.json();
                setUserData(data);
                
                const ws = new WebSocket(`wss://art-generator-utpn6iuzbq-uc.a.run.app/core/ws/?token=${userAccess}`);
            
                ws.addEventListener('open', () => {
                });
        
                ws.addEventListener('message', (event) => {
                });
        
                ws.addEventListener('close', (event) => {
                });
        
                ws.addEventListener('error', (error) => {
                });
                
                setWs(ws);

            if(data.generations_left > 1 || data.plan !== 'free') {
                navigate(`/result/${uuidv4()}`);
            }else {
                setGenUpdate(true);
            }
        } else {
            setAuth(1);
        }
    }

    const [activeTab, setActiveTab] = useState('prompt');

    useEffect(() => {
        const updateIndexArray = () => {
            const newWidth = window.innerWidth;
            
            if (newWidth > 767) {
                setActiveTab('prompt');
            }
        };

        updateIndexArray();

        window.addEventListener('resize', updateIndexArray);

        return () => {
            window.removeEventListener('resize', updateIndexArray);
        };
    }, []);

    const hendleRatio = (rt) => {
        setGenSize(rt.size);
    }

    const hendleStyle = (st) => {
        setGenStyle(st.name);
    }

    return (
        <>
            {activeTab === 'prompt' ? (
                <div className="bg-[#0F172A] md:bg-[#F8FAFC] rounded-[0] md:rounded-2xl w-full relative z-10 md:mt-[64px]">
                    <div className="absolute left-4 top-4 uppercase text-[#CBD5E1] md:text-[#0F172A] z-0 text-[10px] font-semibold leading-[18px] md:text-[12px]">
                        Enter prompt... <span className="text-[#64748B]">
                            {genPrompt.length}/250
                        </span>
                    </div>
                    <input 
                        type="text" 
                        className="p-4 pt-[42px] text-[14px] pb-[82px] h-[142px] w-full relative z-10 bg-transparent md:h-[82px] md:pb-4 md:text-[18px] rounded-2xl text-white md:text-[#0F172A]"
                        value={genPrompt}
                        onChange={(e) => {setGenPromp(e.target.value)}}
                        maxLength={250}
                        placeholder="Fuzzy bear wearing yellow sunglasses..."
                    />
                    <div>
                        <div className="absolute z-20 top-2 right-4 flex justify-between items-start gap-1 md:w-auto md:right-[334px] md:top-4 md:gap-2">
                            <div className="relative block md:hidden">
                                <button onClick={() => {setActiveTab(null)}} className="text-[#6EE7B7] text-[12px] font-semibold leading-[18px]">
                                    Close
                                </button>
                            </div>
                            <div className="relative hidden md:block">
                                <button 
                                    ref={sizeRef}
                                    onClick={() => {setSizeButton(!sizeButton)}}
                                    className={`h-[32px] w-[32px] rounded-md bg-[#ffffff] flex justify-center items-center border border-[#334155] md:h-[50px] md:w-[50px]`}
                                >
                                    {genSize === '1:1' && 
                                        <div 
                                            className={`w-[20px] h-[20px] bg-[#6EE7B7] rounded-[2px] md:w-[32px] md:h-[32px]`}
                                        ></div>
                                    }
                                    {genSize === '3:4' && 
                                        <div 
                                            className={`w-[15px] h-[20px] bg-[#6EE7B7] rounded-[2px] md:w-[24px] md:h-[32px]`}
                                        ></div>
                                    }
                                    {genSize === '4:3' && 
                                        <div 
                                            className={`w-[20px] h-[15px] bg-[#6EE7B7] rounded-[2px] md:w-[32px] md:h-[24px]`}
                                        ></div>
                                    }
                                    {genSize === '16:9' && 
                                        <div 
                                            className={`w-[20px] h-[11px] bg-[#6EE7B7] rounded-[2px] md:w-[32px] md:h-[17px]`}
                                        ></div>
                                    }
                                    
                                    <div className={`absolute w-auto h-[32px] md:h-[50px] md:right-[54px] rounded-md bg-[#0F172A] right-[36px] top-[0] flex transition-all ${sizeButton ? 'px-2 gap-2' : 'px-0 gap-0'}`}>
                                        {ratio.map((rt, index) => (
                                            <div 
                                                key={index} 
                                                className="flex items-center cursor-pointer"
                                                onClick={() => {setGenSize(rt.size);}}
                                            >
                                                <div className={`rounded-[2px] bg-[#ffffff] transition-all ${sizeButton ? 'max-w-auto max-h-auto' : 'max-w-[0px] max-h-[0px]'}`} style={{height: `${rt.height * 1.3}px`, width: `${rt.width * 1.3}px`}}></div>
                                            </div>
                                        ))}
                                    </div>
                                </button>
                            </div>
                            <div className="relative hidden md:block">
                                <button 
                                    ref={styleRef}
                                    onClick={() => {setStyleButton(!styleButton)}}
                                    className={`h-[32px] w-[32px] rounded-md bg-[#ffffff] flex justify-center items-center border border-[#334155] md:h-[50px] md:w-[50px]`}
                                >
                                    {genStyle === 'none' && 
                                        <div 
                                            className={`w-[20px] h-[20px] bg-[#6EE7B7] rounded-[2px] md:w-[32px] md:h-[32px]`}
                                        >
                                            <img src={None} alt="" />
                                        </div>
                                    }
                                    {genStyle === 'oil' && 
                                        <div 
                                            className={`w-[20px] h-[20px] bg-[#6EE7B7] rounded-[2px] md:w-[32px] md:h-[32px]`}
                                        >
                                            <img src={Oil} alt="" />
                                        </div>
                                    }
                                    {genStyle === 'drawing' && 
                                        <div 
                                            className={`w-[20px] h-[20px] bg-[#6EE7B7] rounded-[2px] md:w-[32px] md:h-[32px]`}
                                        >
                                            <img src={Drawing} alt="" />
                                        </div>
                                    }
                                    {genStyle === 'cyberpunk' && 
                                        <div 
                                            className={`w-[20px] h-[20px] bg-[#6EE7B7] rounded-[2px] md:w-[32px] md:h-[32px]`}
                                        >
                                            <img src={Cyberpunk} alt="" />
                                        </div>
                                    }
                                    {genStyle === 'wildlife' && 
                                        <div 
                                            className={`w-[20px] h-[20px] bg-[#6EE7B7] rounded-[2px] md:w-[32px] md:h-[32px]`}
                                        >
                                            <img src={Wildlife} alt="" />
                                        </div>
                                    }

                                    <div className={`absolute w-auto h-[32px] rounded-md bg-[#0F172A] right-[36px] md:h-[50px] md:right-[54px] top-[0] flex transition-all ${styleButton ? 'px-2 gap-2' : 'px-0 gap-0'}`}>
                                        {style.map((st, index) => (
                                            <div 
                                                key={index} 
                                                className="flex items-center cursor-pointer"
                                                onClick={() => {setGenStyle(st.name)}}
                                            >
                                                <div className={`w-[20px] h-[20px] md:w-[32px] md:h-[32px] flex items-center justify-center ${styleButton ? 'max-w-auto max-h-auto' : 'max-w-[0px] max-h-[0px]'}`}>
                                                    <img src={st.img} className="rounded-sm" alt="" />
                                                </div>
                                        </div>
                                        ))}
                                    </div>
                                </button>
                            </div>
                        </div>

                        <div className="absolute z-20 bottom-4 right-4 w-[calc(100%-32px)] flex justify-between items-start gap-2 md:w-[310px]">
                            <button 
                                onClick={() => {
                                    if(!loading) {
                                        SurpriseMe()
                                    }
                                }} 
                                className={`flex-1 w-1/2 hover:bg-[#0F172A] hover:border-[#0F172A] hover:text-[#F8FAFC] transition-all flex gap-1 items-center justify-center border rounded-lg h-[40px] text-[14px] md:text-[18px] font-semibold md:h-[50px] ${loading ? 'cursor-no-drop bg-[#0F172A] border-[#0F172A] text-[#F8FAFC]' : 'border-[#CBD5E1] bg-[#F8FAFC] text-[#0F172A] hover:bg-[#0F172A] hover:border-[#0F172A] hover:text-[#F8FAFC]'}`}
                                disabled={loading}
                            >
                                {loading ?
                                    <div className="flex justify-center items-center">
                                        <div className="w-[28px] h-[28px] rounded-full animate-spin border-2 border-solid border-[#F8FAFC] border-t-transparent"></div>
                                    </div>
                                    :
                                    <>
                                        <PiLightningFill className="text-[18px]" /> Surprise Me
                                    </>
                                }
                            </button>
                            <button
                                onClick={() => {generateLink()}}
                                className={`flex-1 w-1/2 flex gap-2 items-center justify-center bg-[#6EE7B7] border border-[#6EE7B7] rounded-lg h-[40px] text-[#0F172A] text-[14px] hover:bg-[#10B981] hover:border-[#10B981] transition-all md:text-[18px] font-semibold md:h-[50px] ${genPrompt.length === 0 && 'opacity-50 cursor-no-drop'}`}
                                disabled={genPrompt.length === 0}
                            >
                                Generate
                            </button>
                        </div>
                    </div>
                </div>
            ) : activeTab === 'size' ? (
                <div className="bg-[#1E293B] border-b border-[#0F172A] md:bg-[#F8FAFC] rounded-[0] md:rounded-2xl w-full relative z-10 md:mt-[64px]">
                    <div className="absolute left-4 top-4 uppercase text-[#CBD5E1] md:text-[#0F172A] z-0 text-[10px] font-semibold leading-[18px] md:text-[12px]">
                        Aspect ratio
                    </div>
                    <div className="p-4 pt-[26px] text-[14px] pb-[16px] h-[142px] w-full relative z-10 bg-transparent md:h-[82px] md:pb-4 md:text-[18px] rounded-2xl text-white md:text-[#0F172A]">
                        <div className="flex gap-2 justify-center mt-4">
                            {ratio.map((rt, index) => (
                                <div key={index} className={`flex flex-col gap-4 items-center justify-center p-2 cursor-pointer rounded-lg ${genSize === rt.size ? 'bg-gray-700' : "bg-transparent"}`} onClick={() => hendleRatio(rt)}>
                                    <div className="w-[28px] h-[28px] flex items-center justify-center">
                                        <div className={`rounded-sm ${genSize === rt.size ? 'bg-gray-50' : "bg-gray-400"}`} style={{height: `${rt.height * 1.4}px`, width: `${rt.width * 1.4}px`}}></div>
                                    </div>
                                    <div className={`px-3 py-1 text-[14px] leading-[17px] text-gray-50 font-semibold`}>{rt.size}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="absolute z-20 top-2 right-4 flex justify-between items-start gap-1 md:w-auto md:right-[334px] md:top-4 md:gap-2">
                        <div className="relative block md:hidden">
                            <button onClick={() => {setActiveTab(null)}} className="text-[#6EE7B7] text-[12px] font-semibold leading-[18px]">
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            ) : activeTab === 'style' ? (
                <div className="bg-[#1E293B] border-b border-[#0F172A] md:bg-[#F8FAFC] rounded-[0] md:rounded-2xl w-full relative z-10 md:mt-[64px]">
                    <div className="absolute left-4 top-4 uppercase text-[#CBD5E1] md:text-[#0F172A] z-0 text-[10px] font-semibold leading-[18px] md:text-[12px]">
                        Image Settings
                    </div>
                    <div className="p-4 pt-[26px] text-[14px] pb-[16px] h-[142px] w-full relative z-10 bg-transparent md:h-[82px] md:pb-4 md:text-[18px] rounded-2xl text-white md:text-[#0F172A]">
                        <div className="flex gap-2 justify-start mt-4 overflow-x-auto">
                            {style.map((st, index) => (
                                <div key={index} className={`flex flex-col gap-2 items-center justify-center p-2 cursor-pointer rounded-lg ${genStyle === st.name ? 'bg-gray-700' : "bg-transparent"}`} onClick={() => hendleStyle(st)}>
                                    <div className="w-[40px] h-[40px] flex items-center justify-end rounded-[4px] overflow-hidden">
                                        <img src={st.img} className="rounded-sm" alt="" />
                                    </div>
                                    <div className={`text-[12px] leading-[18px] font-semibold capitalize ${setGenStyle === st.name ? 'text-green-300' : 'text-gray-50'}`}>{st.name}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="absolute z-20 top-2 right-4 flex justify-between items-start gap-1 md:w-auto md:right-[334px] md:top-4 md:gap-2">
                        <div className="relative block md:hidden">
                            <button onClick={() => {setActiveTab(null)}} className="text-[#6EE7B7] text-[12px] font-semibold leading-[18px]">
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            ) : null

            }

            <div className="flex md:hidden w-full bg-[#1E293B] p-4 justify-center items-start gap-2">
                <div onClick={() => {setActiveTab('prompt')}} className="w-8 h-8 flex justify-center items-center flex-col gap-2 cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12" fill="none">
                        <path d="M1 1L6 6L1 11M9 11H15" stroke="#F8FAFC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    <div className={`w-1 h-1 rounded-full ${activeTab === 'prompt' ? 'bg-[#6EE7B7]' : 'bg-transparent'}`}></div>
                </div>
                <div onClick={() => {setActiveTab('size')}} className="w-8 h-8 flex justify-center items-center flex-col gap-2 cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14" fill="none">
                        <rect x="0.75" y="0.75" width="16.5" height="12.5" rx="1.25" stroke="#F8FAFC" strokeWidth="1.5"/>
                        <rect x="0.75" y="4.75" width="8.5" height="8.5" rx="1.25" stroke="#F8FAFC" strokeWidth="1.5"/>
                        <rect x="0.75" y="0.75" width="4.5" height="12.5" rx="1.25" stroke="#F8FAFC" strokeWidth="1.5"/>
                    </svg>
                    <div className={`w-1 h-1 rounded-full ${activeTab === 'size' ? 'bg-[#6EE7B7]' : 'bg-transparent'}`}></div>
                </div>
                <div onClick={() => {setActiveTab('style')}} className="w-8 h-8 flex justify-center items-center flex-col gap-2 cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
                        <path d="M9 9.75C11.4162 9.75 13.375 7.79125 13.375 5.375C13.375 2.95875 11.4162 1 9 1C6.58375 1 4.625 2.95875 4.625 5.375C4.625 7.79125 6.58375 9.75 9 9.75Z" stroke="#F8FAFC" strokeWidth="1.5" strokeLinejoin="round"/>
                        <path d="M12.207 15C14.6233 15 16.582 13.0412 16.582 10.625C16.582 8.20875 14.6233 6.25 12.207 6.25C9.79079 6.25 7.83203 8.20875 7.83203 10.625C7.83203 13.0412 9.79079 15 12.207 15Z" stroke="#F8FAFC" strokeWidth="1.5" strokeLinejoin="round"/>
                        <path d="M5.79102 15C8.20726 15 10.166 13.0412 10.166 10.625C10.166 8.20875 8.20726 6.25 5.79102 6.25C3.37477 6.25 1.41602 8.20875 1.41602 10.625C1.41602 13.0412 3.37477 15 5.79102 15Z" stroke="#F8FAFC" strokeWidth="1.5" strokeLinejoin="round"/>
                    </svg>
                    <div className={`w-1 h-1 rounded-full ${activeTab === 'style' ? 'bg-[#6EE7B7]' : 'bg-transparent'}`}></div>
                </div>
            </div>
        </>
    );
}
 
export default InputDashboard;