import { useEffect, useState } from "react";
import { useUser } from "../../../provider/UserProvider";
import { useFilter } from "../../../provider/filterProvider";
import DownloadButton from "../../../component/button/DownloadButton";
import { PiHeartFill } from "react-icons/pi";
import { FaHeart } from "react-icons/fa";

const Saved = () => {
    const { userAccess } = useUser();
    const { filterText, filterDate, setFilterShow } = useFilter();
    const [savedImg, setSavedImg] = useState([]);

    const [filteredArray, setFilteredArray] = useState([]);
    const [indexArray, setIndexArray] = useState([0, 1]);
    const [indexArrayValue, setIndexArrayValue] = useState(2);
    const [checkLoading, setCheckLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);
    
    useEffect(() => {
        const updateIndexArray = () => {
        const newWidth = window.innerWidth;
        let newArray;
        let newArrayValue;
        if (newWidth > 1500) {
            newArray = [0, 1, 2, 3, 4];
            newArrayValue = 5;
        } else if (newWidth > 950) {
            newArray = [0, 1, 2, 3];
            newArrayValue = 4;
        } else if (newWidth > 767) {
            newArray = [0, 1, 2];
            newArrayValue = 3;
        } else if (newWidth > 380) {
            newArray = [0, 1];
            newArrayValue = 2;
        } else {
            newArray = [0];
            newArrayValue = 1;
        }
        setIndexArray(newArray);
        setIndexArrayValue(newArrayValue);
        };

        updateIndexArray();

        window.addEventListener('resize', updateIndexArray);

        return () => {
            window.removeEventListener('resize', updateIndexArray);
        };
    }, []);

    const savedImage = async () => {
        setPageLoading(true);
        try {
            const response = await fetch('https://api.betterartist.ai/api/core/art/saved_images', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userAccess}`,
                }
            });
        
            if (response.ok) {
                const data = await response.json();
                setSavedImg(data);
                setPageLoading(false);
            }
        } catch (error) {
            setPageLoading(false);
            return null;
        }
    }

    useEffect(() => {
        savedImage()
    }, [userAccess, checkLoading])


    useEffect(() => {
        const filteredArr = savedImg.filter(item => {
          const isTextMatch = filterText === '' || item.art.prompt.toLowerCase().includes(filterText.toLowerCase());
          if (filterDate === '') {
            return isTextMatch;
          }
    
          const createdAtDate = new Date(item.created_at);
          const currentDate = new Date();
    
          let isDateMatch = true;
    
          if (filterDate === 'day') {
            const twentyFourHoursAgo = new Date(currentDate - 24 * 60 * 60 * 1000);
            isDateMatch = createdAtDate >= twentyFourHoursAgo;
          } else if (filterDate === 'lastday') {
            const fortyEightHoursAgo = new Date(currentDate - 48 * 60 * 60 * 1000);
            isDateMatch = createdAtDate >= fortyEightHoursAgo;
          } else if (filterDate === 'lastweek') {
            const sevenDaysAgo = new Date(currentDate - 7 * 24 * 60 * 60 * 1000);
            isDateMatch = createdAtDate >= sevenDaysAgo;
          } else if (filterDate === 'lastmonth') {
            const twentyEightDaysAgo = new Date(currentDate - 28 * 24 * 60 * 60 * 1000);
            isDateMatch = createdAtDate >= twentyEightDaysAgo;
          }
    
          return isTextMatch && isDateMatch;
        });
    
        setFilteredArray(filteredArr);
        setFilterShow(filteredArr);
    }, [filterText, filterDate, savedImg]);

    const postDeleteImg = async (id) => {
        if(!checkLoading) {
            setCheckLoading(true);
            try {
                const response = await fetch(`https://api.betterartist.ai/api/core/art/saved_images/${id}`, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `Bearer ${userAccess}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        id: id
                    }),
                });
            
                if (!response.ok) {
                    return null
                }

                setCheckLoading(false);
            
            } catch (error) {
                console.error(error);
                setCheckLoading(false);
            }
        }
    };


    return (  
        <>
            <div className="p-4 pt-0">
                <div className="w-full gap-2 inline-flex md:gap-7">
                    {filteredArray.length !== 0 ?
                        <>
                            {indexArray.map((columnIndex) => (
                                <div key={columnIndex} className="flex flex-col gap-2 w-full flex-auto md:gap-7">
                                    {filteredArray
                                    .filter((_, index) => (index % indexArrayValue) === columnIndex)
                                    .map((img, index) => (
                                        <div key={index} className="w-full relative">
                                            <img 
                                                src={img.image_url} 
                                                alt="" 
                                                className="w-full h-auto rounded-lg md:rounded-2xl relative z-0" 
                                            />
                                            <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-between md:bg-[rgba(25,31,40,0.40)] z-10 rounded-lg md:rounded-2xl transition-all md:opacity-0 hover:opacity-100">
                                                <div className="w-full flex justify-end items-center p-4 md:p-[5%] gap-[10px]">
                                                    <DownloadButton img={img.image_url}/>
                                                    {checkLoading ?
                                                        <button className={`h-[40px] w-[40px] md:h-[4.5vw] md:w-[4.5vw] md:max-h-[50px] md:max-w-[50px] rounded-full border flex justify-center items-center text-2xl border-green-300 text-green-300`}>
                                                            <div className="w-[28px] h-[28px] rounded-full animate-spin border-2 border-solid border-green-300 border-t-transparent"></div>
                                                        </button>
                                                        :
                                                        <button onClick={() => {postDeleteImg(img.id)}} className={`h-[40px] w-[40px] md:h-[4.5vw] md:w-[4.5vw] md:max-h-[50px] md:max-w-[50px] rounded-full border flex justify-center items-center text-2xl md:text-[26px] border-green-300 text-green-300`}>
                                                            <PiHeartFill />
                                                        </button>
                                                    }
                                                </div>
                                                <div className="hidden md:block w-full px-4 pb-4 md:px-[5%] md:pb-[5%] pt-0">
                                                    <div className="text-gray-50 text-[16px] leading-[24px] font-semibold truncate overflow-ellipsis mb-1">{img.art.prompt}</div>
                                                    <div className="flex gap-2">
                                                        <div className="px-2 py-1 rounded-[100px] bg-gray-50/40 text-[12px] text-gray-50 flex gap-[4px] items-center justify-center">
                                                            {img.art.aspect_ratio === '1:1' ? 
                                                                ('Square')
                                                                : img.art.aspect_ratio === '3:4' ?
                                                                ('Portrait')
                                                                : img.art.aspect_ratio === '4:3' ?
                                                                ('Landscape')
                                                                : img.art.aspect_ratio === '16:9' ?
                                                                ('Widescreen')
                                                                : null
                                                            }
                                                            <div className="py-[2px] px-2 border border-gray-50 rounded-[50px]">
                                                                {img.art.aspect_ratio}
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="px-3 py-2 rounded-[100px] bg-gray-50/40 text-[14px] text-gray-50 flex items-center justify-center capitalize">
                                                            {img.art.style}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </>
                        :
                        <div className="w-full p-10 flex justify-center items-center">
                            {pageLoading ?
                                <div className="w-[28px] h-[28px] rounded-full animate-spin border-2 border-solid border-green-300 border-t-transparent"></div>
                            :
                                <div className="flex justify-center items-center gap-6 flex-col">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="120" height="121" viewBox="0 0 120 121" fill="none">
                                        <circle cx="60" cy="60.5" r="60" fill="#334155"/>
                                        <path d="M83.9055 39.9204C82.5048 38.519 80.8417 37.4073 79.0112 36.6488C77.1807 35.8904 75.2188 35.5 73.2374 35.5C71.256 35.5 69.294 35.8904 67.4635 36.6488C65.6331 37.4073 63.9699 38.519 62.5692 39.9204L59.6622 42.8274L56.7552 39.9204C53.9258 37.091 50.0884 35.5015 46.0871 35.5015C42.0857 35.5015 38.2483 37.091 35.4189 39.9204C32.5895 42.7497 31 46.5872 31 50.5885C31 54.5899 32.5895 58.4273 35.4189 61.2567L38.3259 64.1637L59.6622 85.5L80.9985 64.1637L83.9055 61.2567C85.3069 59.856 86.4186 58.1928 87.1771 56.3624C87.9355 54.5319 88.3259 52.5699 88.3259 50.5885C88.3259 48.6071 87.9355 46.6452 87.1771 44.8147C86.4186 42.9842 85.3069 41.3211 83.9055 39.9204Z" fill="#0F172A"/>
                                    </svg>
                                    <div className="text-[23px] leading-[28px] font-bold text-[#F8FAFC]">No Saved Images</div>
                                    <div className="text-[14px] md:text-[16px] text-center leading-[25px] font-semibold text-[#F8FAFC]">To get started, generate images using a prompt.</div>
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>
        </>
    );
}
 
export default Saved;
    