import { useEffect, useState } from 'react';
import './App.css';
import AuthModal from './auth/AuthModal';
import NavBar from './component/header/navBar';
import { useUser } from './provider/UserProvider';
import Cookies from 'js-cookie';
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import Bg from './assets/image/Bg.png';
import Landing from './page/Landing';
import ResultPage from './page/result/ResultPage';
import Dashboard from './page/dashboard/Dashboard';
import Upgrade from './page/upgrade/Upgrade';
import PaywallCheckout from './page/upgrade/PaywallCheckout';
import SamePage from './page/same/SamePage';
import { useGen } from './provider/genProvider';
import Update from './assets/image/update.jpg';
import ForgotModal from './auth/ForgotPassword/ForgotModal';

function App() {
  const { setUserAccess, auth, setAuth, forgotPassword } = useUser();
  const { genUpdate, setGenUpdate } = useGen();
  const [confirmationMail, setConfirmationMail] = useState(false);

  useEffect(() => {
    const currentUrl = window.location.href;

    const tokenIndex = currentUrl.indexOf('confirm-email/');
    if (tokenIndex !== -1) {
      const extractedToken = currentUrl.substring(tokenIndex + 'confirm-email/'.length);
      confirmToken(extractedToken);
    }
  }, []);

  useEffect(() => {
    if(Cookies.get('refresh')) {
      refreshToken();
    }
  }, [])

  async function refreshToken() {
    try {
      const response = await fetch('https://api.betterartist.ai/api/token/refresh', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          refresh: Cookies.get('refresh'),
        })
      });

      if (!response.ok) {
        return null;
      }
      const data = await response.json();
      setUserAccess(data.access);

    } catch (error) {
      console.error('Помилка при оновленні токена:', error);
    }
  }

  async function confirmToken(tokenValue) {

    try {
      const response = await fetch('https://api.betterartist.ai/api/core/user/confirm-email/' + tokenValue, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (!response.ok) {
        return null;
      }
      const data = await response.json();
      console.log(data);
      setConfirmationMail(true);

    } catch (error) {
      console.error('Помилка при оновленні токена:', error);
    }
  }

  return (
    <>
      <div className='absolute top-0 left-0 z-[-1] h-full w-full overflow-hidden'>
        <div className='min-h-full min-w-full absolute z-[1]' style={{background: 'linear-gradient(to bottom, transparent 0%, transparent 80%, #0F172A 100%)'}}></div>
        <img src={Bg} alt='' className='min-h-full min-w-full relative z-0' />
      </div>
      <NavBar />
      {genUpdate &&
        <div className='fixed top-0 left-0 w-full h-screen bg-[#191F28]/60 flex justify-center items-center z-[10000]'>
          <div className='bg-[transparent] h-full w-full md:h-auto md:w-auto flex flex-col md:flex-row-reverse justify-start items-start relative md:rounded-[16px] overflow-hidden'>
            <div className='absolute top-0 left-0 z-[-1] h-full w-full overflow-hidden'>
              <div className='min-h-full min-w-full absolute z-[1]' style={{background: 'linear-gradient(to bottom, transparent 0%, transparent 80%, #0F172A 100%)'}}></div>
              <img src={Bg} alt='' className='min-h-full min-w-full relative z-0' />
            </div>
            <div className='flex md:hidden p-[18px] w-full border-b border-[#1E293B] justify-center text-[23px] leading-[28px] font-extrabold text-[#F8FAFC]'>
              BetterArtist.ai
            </div>
            <div className='p-6 md:p-0'>
              <div className='rounded-[16px] md:rounded-[0] flex justify-center items-center w-[calc(100vw-48px)] h-[calc(45vh-48px)] overflow-hidden md:h-[440px] md:w-auto'>
                <img className='w-full md:w-auto h-auto md:h-full' src={Update} alt='' />
              </div>
            </div>
            <div className='p-6 md:p-10 max-w-[440px] flex flex-col gap-4'>
              
              <div className='hidden md:flex w-full justify-start text-[23px] leading-[28px] font-extrabold text-[#F8FAFC] mb-6'>
                BetterArtist.ai
              </div>
              <div className='text-[23px] leading-7 font-bold text-center text-[#F8FAFC] md:text-[28px] md:leading-9 md:font-extrabold md:text-left'>
                Generate more images with a <span className='text-[#6EE7B7]'>premium plan</span>
              </div>
              <div className='text-[14px] leading-[20px] font-medium text-center text-[#F8FAFC] md:text-[16px] md:leading-[22px] md:text-left'>
                Upgrade your account to generate more high resolution images.
              </div>
              <Link onClick={() => {setGenUpdate(false)}} to={`/upgrade`} className='w-full bg-[#6EE7B7] mt-2 text-[#0F172A] rounded-lg text-[16px] leading-6 md:text-[18px] px-4 py-3 md:py-4 text-center font-semibold'>
                Upgrade Plans
              </Link>
              <button onClick={() => {setGenUpdate(false)}} className='text-[14px] leading-4 text-[#F8FAFC] font-semibold mt-2'>
                No thanks
              </button>
            </div>
          </div>
        </div>
      }
      {auth !== 0 &&
        <AuthModal />
      }
      {forgotPassword !== 0 &&
        <ForgotModal />
      }
      {confirmationMail &&
        <div className="w-full h-screen fixed bg-[#191F28]/60 top-0 left-0 flex justify-center items-center z-[100]">
          <div className="p-4 bg-[#1E293B] w-full md:max-w-[440px] h-screen md:h-auto md:p-10 md:rounded-[14px] flex justify-center items-center flex-col gap-[24px]">

            <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 120 120" fill="none">
              <circle cx="60.5" cy="60" r="44.5" fill="#6EE7B7"/>
              <path d="M120 60C120 75.913 113.679 91.1742 102.426 102.426C91.1742 113.679 75.913 120 60 120C44.087 120 28.8258 113.679 17.5736 102.426C6.32141 91.1742 0 75.913 0 60C0 44.087 6.32141 28.8258 17.5736 17.5736C28.8258 6.32141 44.087 0 60 0C75.913 0 91.1742 6.32141 102.426 17.5736C113.679 28.8258 120 44.087 120 60ZM90.225 37.275C89.6893 36.7411 89.0514 36.3208 88.3495 36.0391C87.6476 35.7574 86.8961 35.6202 86.1399 35.6356C85.3838 35.651 84.6385 35.8187 83.9486 36.1288C83.2588 36.4388 82.6385 36.8848 82.125 37.44L56.0775 70.6275L40.38 54.9225C39.3137 53.9289 37.9033 53.388 36.4461 53.4137C34.9888 53.4394 33.5984 54.0297 32.5678 55.0603C31.5372 56.0909 30.9469 57.4813 30.9212 58.9386C30.8955 60.3958 31.4364 61.8062 32.43 62.8725L52.275 82.725C52.8096 83.2586 53.4462 83.6791 54.1469 83.9614C54.8475 84.2437 55.5979 84.3819 56.3531 84.3679C57.1083 84.3539 57.853 84.188 58.5427 83.8799C59.2324 83.5719 59.853 83.1281 60.3675 82.575L90.3075 45.15C91.3282 44.0887 91.8921 42.6696 91.878 41.1972C91.864 39.7248 91.2732 38.3166 90.2325 37.275H90.225Z" fill="#334155"/>
            </svg>

            <div className='text-[28px] font-extrabold text-[#F8FAFC] text-center'>
              Your payment was successful!
            </div>

            <button 
              onClick={() => {setAuth(1); setConfirmationMail(false)}}
              className='flex-1 w-1/2 flex gap-2 p-2 items-center justify-center bg-[#6EE7B7] border border-[#6EE7B7] hover:bg-[#10B981] hover:border-[#10B981] transition-all  rounded-lg h-[40px] text-[#0F172A] text-[14px] md:text-[18px] font-semibold md:h-[50px]'>
              Login
            </button>
            
            <button className='text-[14px] font-semibold text-[#F8FAFC] text-center' onClick={() => {setConfirmationMail(false)}}>
              close
            </button>

          </div>
        </div>
      }
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="confirm-email">
          <Route path=":tokenValue" element={<Landing />} />
        </Route>
        <Route path="result">
          <Route path=":uuid" element={<ResultPage />} />
        </Route>
        <Route path="same">
          <Route path=":uuid" element={<SamePage />} />
        </Route>
        <Route path="dashboard">
          <Route path=":page" element={<Dashboard />} />
        </Route>
        <Route path="upgrade" element={<Upgrade />}/>
        <Route path='checkout/:planName/:id' element={<PaywallCheckout />} />
        <Route path='checkout/:planName/:id' element={<PaywallCheckout />} />
        {/* {userData ?https://betterartist.ai/?token=O4MPsRXKNzdT5T2ZrkD47bMFGlA
          <>
            <Route path="dashboard">
              <Route path=":page" element={<Dashboard />} />
            </Route>
            <Route path="result">
              <Route path=":uuid" element={<Result ws={ws} />} />
            </Route>
            <Route path="result-same">
              <Route path=":uuid/:img/:type" element={<Result ws={ws} />} />
            </Route>
          </>
          :
          <>
            <Route path="dashboard">
              <Route path=":page" element={<LoadingPage />} />
            </Route>
            <Route path="result">
              <Route path=":uuid" element={<LoadingPage />} />
            </Route>
            <Route path="result-same">
              <Route path=":uuid" element={<LoadingPage />} />
            </Route>
          </>
        }
        <Route path="upgrade" element={<Upgrade userData={userData} />}/>
        <Route path='checkout/:planName/:id' element={<PaywallCheckout userData={userData} clientSecret={clientSecret} setClientSecret={setClientSecret} />} />
        <Route path="*" element={<NoMatch />} /> */}
      </Routes>
    </>
  );
}

export default App;
