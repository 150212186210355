import { Link, useNavigate } from "react-router-dom";
import { useGen } from "../../provider/genProvider";
import { PiLightningFill } from "react-icons/pi";
import { useEffect, useRef, useState } from "react";

import None from "../../assets/image/styleImg/None.png";
import Oil from "../../assets/image/styleImg/Oil.png";
import Drawing from "../../assets/image/styleImg/Drawing.png";
import Cyberpunk from "../../assets/image/styleImg/Cyberpunk.png";
import Wildlife from "../../assets/image/styleImg/Wildlife.png";
import { useUser } from "../../provider/UserProvider";
import { v4 as uuidv4 } from 'uuid';


const Input = () => {
    const { genPrompt, setGenPromp, genStyle, setGenStyle, genSize, setGenSize, setGenUpdate } = useGen();
    const { userAccess, setAuth, setUserData, setWs } = useUser();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    
    const [sizeButton, setSizeButton] = useState(false);
    const [styleButton, setStyleButton] = useState(false);
    const sizeRef = useRef(null);
    const styleRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (sizeRef.current && !sizeRef.current.contains(event.target)) {
                setSizeButton(false);
            }
        };
    
        if (sizeButton) {
            document.addEventListener('click', handleClickOutside);
        } else {
            document.removeEventListener('click', handleClickOutside);
        }
    
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [sizeButton]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (styleRef.current && !styleRef.current.contains(event.target)) {
                setStyleButton(false);
            }
        };
    
        if (styleButton) {
            document.addEventListener('click', handleClickOutside);
        } else {
            document.removeEventListener('click', handleClickOutside);
        }
    
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [styleButton]);

    const SurpriseMe = async () => {
        setLoading(true);

        try {
            const response = await fetch('https://api.betterartist.ai/api/core/art/prompt', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if (response.ok) {
                const data = await response.json();
                setGenPromp(data.prompt);
                return setLoading(false);
            }
            return null;
        } catch (error) {
            return setLoading(false);
        }
    }

    const ratio = [
        {
            name: 'Landscape',
            size: '4:3',
            width: '16',
            height: '12'
        },
        {
            name: 'Portrait',
            size: '3:4',
            width: '12',
            height: '16'
        },
        {
            name: 'Square',
            size: '1:1',
            width: '16',
            height: '16'
        },
        {
            name: 'Widescreen',
            size: '16:9',
            width: '16',
            height: '9'
        }
    ]

    const style = [
        {
            name: 'none',
            img: None
        },
        {
            name: 'oil',
            img: Oil
        },
        {
            name: 'drawing',
            img: Drawing
        },
        {
            name: 'cyberpunk',
            img: Cyberpunk
        },
        {
            name: 'wildlife',
            img: Wildlife
        }, 
    ]

    async function generateLink() {
        if(userAccess) {
            const userMe = await fetch('https://api.betterartist.ai/api/core/user/me', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${userAccess}`,
                },
            });

            if (!userMe.ok) {
                throw new Error('Помилка при виконанні GET запиту');
            }

                const data = await userMe.json();
                setUserData(data);
                
                const ws = new WebSocket(`wss://art-generator-utpn6iuzbq-uc.a.run.app/core/ws/?token=${userAccess}`);
            
                ws.addEventListener('open', () => {
                });
        
                ws.addEventListener('message', (event) => {
                });
        
                ws.addEventListener('close', (event) => {
                });
        
                ws.addEventListener('error', (error) => {
                });
                
                setWs(ws);

            if(data.generations_left > 1 || data.plan !== 'free') {
                navigate(`/result/${uuidv4()}`);
            }else {
                setGenUpdate(true);
            }
        } else {
            setAuth(1);
        }
    }

    return (
        <div className="bg-[#F8FAFC] rounded-2xl w-full relative z-10 md:mt-[64px]" id='inputGen'>
            <div className="absolute left-4 top-4 uppercase text-[#0F172A] z-0 text-[10px] font-semibold leading-[18px] md:text-[12px]">
                Enter prompt... <span className="text-[#64748B]">
                    {genPrompt.length}/250
                </span>
            </div>
            <input 
                type="text" 
                className="p-4 pt-[42px] text-[14px] pb-[72px] h-[132px] w-full relative z-10 bg-transparent md:h-[82px] md:pb-4 md:text-[18px] rounded-2xl"
                value={genPrompt}
                onChange={(e) => {setGenPromp(e.target.value)}}
                maxLength={250}
                placeholder="Fuzzy bear wearing yellow sunglasses..."
            />
            <div>
                <div className="absolute z-20 top-2 right-4 flex justify-between items-start gap-1 md:w-auto md:right-[334px] md:top-4 md:gap-2">
                    <div className="relative">
                        <button 
                            ref={sizeRef}
                            onClick={() => {setSizeButton(!sizeButton)}}
                            className={`h-[32px] w-[32px] rounded-md bg-[#ffffff] flex justify-center items-center border border-[#334155] md:h-[50px] md:w-[50px]`}
                        >
                            {genSize === '1:1' && 
                                <div 
                                    className={`w-[20px] h-[20px] bg-[#6EE7B7] rounded-[2px] md:w-[32px] md:h-[32px]`}
                                ></div>
                            }
                            {genSize === '3:4' && 
                                <div 
                                    className={`w-[15px] h-[20px] bg-[#6EE7B7] rounded-[2px] md:w-[24px] md:h-[32px]`}
                                ></div>
                            }
                            {genSize === '4:3' && 
                                <div 
                                    className={`w-[20px] h-[15px] bg-[#6EE7B7] rounded-[2px] md:w-[32px] md:h-[24px]`}
                                ></div>
                            }
                            {genSize === '16:9' && 
                                <div 
                                    className={`w-[20px] h-[11px] bg-[#6EE7B7] rounded-[2px] md:w-[32px] md:h-[17px]`}
                                ></div>
                            }
                            
                            <div className={`absolute w-auto h-[32px] md:h-[50px] md:right-[54px] rounded-md bg-[#0F172A] right-[36px] top-[0] flex transition-all ${sizeButton ? 'px-2 gap-2' : 'px-0 gap-0'}`}>
                                {ratio.map((rt, index) => (
                                    <div 
                                        key={index} 
                                        className="flex items-center cursor-pointer"
                                        onClick={() => {setGenSize(rt.size);}}
                                    >
                                        <div className={`rounded-[2px] bg-[#ffffff] transition-all ${sizeButton ? 'max-w-auto max-h-auto' : 'max-w-[0px] max-h-[0px]'}`} style={{height: `${rt.height * 1.3}px`, width: `${rt.width * 1.3}px`}}></div>
                                    </div>
                                ))}
                            </div>
                        </button>
                    </div>
                    <div className="relative">
                        <button 
                            ref={styleRef}
                            onClick={() => {setStyleButton(!styleButton)}}
                            className={`h-[32px] w-[32px] rounded-md bg-[#ffffff] flex justify-center items-center border border-[#334155] md:h-[50px] md:w-[50px]`}
                        >
                            {genStyle === 'none' && 
                                <div 
                                    className={`w-[20px] h-[20px] bg-[#6EE7B7] rounded-[2px] md:w-[32px] md:h-[32px]`}
                                >
                                    <img src={None} alt="" />
                                </div>
                            }
                            {genStyle === 'oil' && 
                                <div 
                                    className={`w-[20px] h-[20px] bg-[#6EE7B7] rounded-[2px] md:w-[32px] md:h-[32px]`}
                                >
                                    <img src={Oil} alt="" />
                                </div>
                            }
                            {genStyle === 'drawing' && 
                                <div 
                                    className={`w-[20px] h-[20px] bg-[#6EE7B7] rounded-[2px] md:w-[32px] md:h-[32px]`}
                                >
                                    <img src={Drawing} alt="" />
                                </div>
                            }
                            {genStyle === 'cyberpunk' && 
                                <div 
                                    className={`w-[20px] h-[20px] bg-[#6EE7B7] rounded-[2px] md:w-[32px] md:h-[32px]`}
                                >
                                    <img src={Cyberpunk} alt="" />
                                </div>
                            }
                            {genStyle === 'wildlife' && 
                                <div 
                                    className={`w-[20px] h-[20px] bg-[#6EE7B7] rounded-[2px] md:w-[32px] md:h-[32px]`}
                                >
                                    <img src={Wildlife} alt="" />
                                </div>
                            }

                            <div className={`absolute w-auto h-[32px] rounded-md bg-[#0F172A] right-[36px] md:h-[50px] md:right-[54px] top-[0] flex transition-all ${styleButton ? 'px-2 gap-2' : 'px-0 gap-0'}`}>
                                {style.map((st, index) => (
                                    <div 
                                        key={index} 
                                        className="flex items-center cursor-pointer"
                                        onClick={() => {setGenStyle(st.name)}}
                                    >
                                        <div className={`w-[20px] h-[20px] md:w-[32px] md:h-[32px] flex items-center justify-center ${styleButton ? 'max-w-auto max-h-auto' : 'max-w-[0px] max-h-[0px]'}`}>
                                            <img src={st.img} className="rounded-sm" alt="" />
                                        </div>
                                </div>
                                ))}
                            </div>
                        </button>
                    </div>
                </div>

                <div className="absolute z-20 bottom-4 right-4 w-[calc(100%-32px)] flex justify-between items-start gap-2 md:w-[310px]">
                    <button 
                        onClick={() => {
                            if(!loading) {
                                SurpriseMe()
                            }
                        }} 
                        className={`flex-1 w-1/2  flex gap-1 items-center justify-center border transition-all rounded-lg h-[40px] text-[14px] md:text-[18px] font-semibold md:h-[50px] ${loading ? 'cursor-no-drop bg-[#0F172A] border-[#0F172A] text-[#F8FAFC]' : 'border-[#CBD5E1] bg-[#F8FAFC] text-[#0F172A] hover:bg-[#0F172A] hover:border-[#0F172A] hover:text-[#F8FAFC]'}`}
                        disabled={loading}
                    >
                        {loading ?
                            <div className="flex justify-center items-center">
                                <div className="w-[28px] h-[28px] rounded-full animate-spin border-2 border-solid border-[#F8FAFC] border-t-transparent"></div>
                            </div>
                            :
                            <>
                                <PiLightningFill className="text-[18px]" /> Surprise Me
                            </>
                        }
                    </button>
                    <button
                        onClick={() => {generateLink()}}
                        className={`flex-1 w-1/2 flex gap-2 items-center justify-center bg-[#6EE7B7] border border-[#6EE7B7] hover:bg-[#10B981] hover:border-[#10B981] transition-all  rounded-lg h-[40px] text-[#0F172A] text-[14px] md:text-[18px] font-semibold md:h-[50px] ${genPrompt.length === 0 && 'opacity-50 cursor-no-drop'}`}
                        disabled={genPrompt.length === 0}
                    >
                        Generate
                    </button>
                </div>
            </div>
        </div>
    );
}
 
export default Input;