import { useState } from "react";
import { PiDownloadSimpleLight } from "react-icons/pi";
import { useUser } from "../../provider/UserProvider";

const DownloadButton = ({img}) => {
    const { userAccess } = useUser();
    const [downloadLoading, setDownloadLoading] = useState(false);

    const downloadImage = async (img) => {
        setDownloadLoading(true)
        try {
            const response = await fetch("https://api.betterartist.ai/api/core/art/download", {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${userAccess}`,
                },
                body: JSON.stringify({
                    image_url: img
                }),
            });
            const data = await response.json();
            const a = document.createElement('a');
            a.href = 'data:image/png;base64,' + data.data;
            a.download = 'image.png';
            a.click();
            setDownloadLoading(false)
            return data;
        } catch (error) {
            console.error("Error fetching prompt:", error);
            setDownloadLoading(false)
            throw error;
        }

    };

    return (  
        <>
            {downloadLoading ?
                <button className='h-[40px] w-[40px] md:h-[4.5vw] md:w-[4.5vw] md:max-h-[50px] md:max-w-[50px] rounded-full border bg-gray-50 border-gray-50 flex justify-center items-center text-2xl md:text-[26px] text-gray-900 rtelative'>  
                    <div className="w-[28px] h-[28px] rounded-full animate-spin border-2 border-solid border-green-300 border-t-transparent"></div>  
                </button>
            :
                <button onClick={() => downloadImage(img)} className='h-[40px] w-[40px] md:h-[4.5vw] md:w-[4.5vw] md:max-h-[50px] md:max-w-[50px] rounded-full border bg-gray-50 border-gray-50 flex justify-center items-center text-2xl md:text-[26px] text-gray-900 rtelative hover:bg-[#CBD5E1] transition-all'>
                    <PiDownloadSimpleLight />
                </button>
            }
        </>
    );
}
 
export default DownloadButton;