import img1 from '../../../assets/image/exploreImg/609c7272-6e8a-4279-92b0-f4a03b0d45c7-0.webp';
import img2 from '../../../assets/image/exploreImg/02aa5ef9-7b45-413d-bf36-5b39a83cc9ae-1.png';
import img3 from '../../../assets/image/exploreImg/55057dac-fd48-4f23-a0e1-b70cc8f34d4d-3.webp';
import img4 from '../../../assets/image/exploreImg/2b8d80fd-a7cb-4408-9622-b859123276cc-3.png';
import img5 from '../../../assets/image/exploreImg/542b11c1-6896-43c9-a8ce-2cca4f1a7988-2.webp';
import img6 from '../../../assets/image/exploreImg/a0dfd7a7-ce3e-4fc9-9f29-7d082270085a-3.webp';
import img7 from '../../../assets/image/exploreImg/947166ff-f82d-4f6d-94de-7ee74a369eef-0.png';
import img8 from '../../../assets/image/exploreImg/1bf67604-2779-473f-b13b-de459d7e3f6a-0.webp';
import img9 from '../../../assets/image/exploreImg/2372bafb-a09b-4797-a045-27725461eb50-3.png';
import { useEffect, useState } from 'react';
import { useGen } from '../../../provider/genProvider';

const Explore = () => {
    const { setGenPromp, setGenStyle, setGenSize } = useGen();

    const exploreData = [
        {
            img: img1,
            prompt: 'Mystical moonlit forest with shimmering lake',
            size: '1:1',
            sizeName: 'Square',
            style: 'cyberpunk',
            styleName: 'cyberpunk'
        },
        {
            img: img2,
            prompt: 'City skyline at twilight with vibrant colors and reflection',
            size: '16:9',
            sizeName: 'Widescreen',
            style: 'oil',
            styleName: 'oil'
        },
        {
            img: img3,
            prompt: 'Majestic aurora illuminates snowy mountain peak at twilight',
            size: '3:4',
            sizeName: 'Portrait',
            style: 'wildlife',
            styleName: 'wildlife'
        },
        {
            img: img4,
            prompt: 'A tranquil sunset over a rolling meadow',
            size: '16:9',
            sizeName: 'Widescreen',
            style: 'sticker',
            styleName: 'sticker'
        },
        {
            img: img9,
            prompt: 'Vibrant autumn leaves swirling around an old wooden bench',
            size: '1:1',
            sizeName: 'Square',
            style: 'default',
            styleName: 'none'
        },
        {
            img: img5,
            prompt: 'A cat looking out the window',
            size: '3:4',
            sizeName: 'Portrait',
            style: 'default',
            styleName: 'none'
        },
        {
            img: img7,
            prompt: 'Abstract shapes in vibrant colors spiraling into an infinite abyss',
            size: '3:4',
            sizeName: 'Portrait',
            style: 'oil',
            styleName: 'oil'
        },
        {
            img: img8,
            prompt: 'Enchanted Forest at Twilight',
            size: '1:1',
            sizeName: 'Square',
            style: 'wildlife',
            styleName: 'wildlife'
        },
        {
            img: img6,
            prompt: 'Serene sunsets over rolling hills and tranquil lakes',
            size: '16:9',
            sizeName: 'Widescreen',
            style: 'default',
            styleName: 'none'
        },
    ];

    const [indexArray, setIndexArray] = useState([0, 1]);
    const [indexArrayValue, setIndexArrayValue] = useState(2);
    
    useEffect(() => {
        const updateIndexArray = () => {
        const newWidth = window.innerWidth;
        let newArray;
        let newArrayValue;
        if (newWidth > 1500) {
            newArray = [0, 1, 2, 3, 4];
            newArrayValue = 5;
        } else if (newWidth > 950) {
            newArray = [0, 1, 2, 3];
            newArrayValue = 4;
        } else if (newWidth > 767) {
            newArray = [0, 1, 2];
            newArrayValue = 3;
        } else if (newWidth > 380) {
            newArray = [0, 1];
            newArrayValue = 2;
        } else {
            newArray = [0];
            newArrayValue = 1;
        }
        setIndexArray(newArray);
        setIndexArrayValue(newArrayValue);
        };

        updateIndexArray();

        window.addEventListener('resize', updateIndexArray);

        return () => {
            window.removeEventListener('resize', updateIndexArray);
        };
    }, []);

    return (
        <>
            <div className="p-4 pt-4">
                <div className="w-full gap-2 inline-flex md:gap-7">
                    <>
                        {indexArray.map((columnIndex) => (
                            <div key={columnIndex} className="flex flex-col gap-2 w-full flex-auto md:gap-7">
                                {exploreData
                                .filter((_, index) => (index % indexArrayValue) === columnIndex)
                                .map((img, index) => (
                                    <div key={index} className="w-full relative">
                                        <img 
                                            src={img.img} 
                                            alt="" 
                                            className="w-full h-auto rounded-lg md:rounded-2xl relative z-0" 
                                        />
                                        <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-between md:bg-[rgba(25,31,40,0.40)] z-10 rounded-lg md:rounded-2xl transition-all md:opacity-0 hover:opacity-100">
                                            <div className="w-full flex justify-end items-center p-4 md:p-[5%] gap-[10px]">
                                                <button 
                                                    className='px-4 py-2 bg-[#F8FAFC] hover:bg-[#E2E8F0] transition-all rounded-lg text-[14px] leading-[18px] md:text-[16px] md:leading-[24px] font-semibold text-[#0F172A]'
                                                    onClick={() => {
                                                        setGenPromp(img.prompt); 
                                                        setGenStyle(img.styleName); 
                                                        setGenSize(img.size); 
                                                    }}
                                                >
                                                    Try Prompt
                                                </button>
                                            </div>
                                            <div className="hidden md:block w-full px-4 pb-4 md:px-[5%] md:pb-[5%] pt-0">
                                                <div className="text-gray-50 text-[16px] leading-[24px] font-semibold truncate overflow-ellipsis mb-1">{img.prompt}</div>
                                                <div className="flex gap-2">
                                                    <div className="px-2 py-1 rounded-[100px] bg-gray-50/40 text-[12px] text-gray-50 flex gap-[4px] items-center justify-center">
                                                        {img.size === '1:1' ? 
                                                            ('Square')
                                                            : img.size === '3:4' ?
                                                            ('Portrait')
                                                            : img.size === '4:3' ?
                                                            ('Landscape')
                                                            : img.size === '16:9' ?
                                                            ('Widescreen')
                                                            : null
                                                        }
                                                        <div className="py-[2px] px-2 border border-gray-50 rounded-[50px]">
                                                            {img.size}
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="px-3 py-2 rounded-[100px] bg-gray-50/40 text-[14px] text-gray-50 flex items-center justify-center capitalize">
                                                        {img.style}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </>
                </div>
            </div>
        </>
    );
};

export default Explore;
