import { useEffect, useState } from "react";
import { useUser } from "../../provider/UserProvider";
import { useNavigate, useParams } from "react-router-dom";
import { useGen } from "../../provider/genProvider";
import DownloadButton from "../../component/button/DownloadButton";
import SaveButton from "../../component/button/SaveButton";
import { v4 as uuidv4 } from 'uuid';

const fetchInitialData = async ({uuid, userAccess, setImages, postNewData, genSameUrl, setGenUpdate}) => {
    try {
        const response = await fetch(`https://api.betterartist.ai/api/core/art/generated/${uuid}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userAccess}`,
            }
        });
        if (response.ok) {
            const data = await response.json();
            setImages(data)
            if(!data?.art_id) {
                postNewData({uuid, userAccess, genSameUrl, setGenUpdate});
            }
            return null;
        }
        return null;
    } catch (error) {
        return null;
    }
}

const postNewData = async ({uuid, userAccess, genSameUrl, setGenUpdate}) => {
    try {
        const response = await fetch(`https://api.betterartist.ai/api/core/art/generate-similar`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${userAccess}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                image_url: genSameUrl,
                uid: uuid,
            }),
        });
        if (response.ok) {
            const data = await response.json();
            return data;
        }
        if (!response.ok) {
            if(response.status === 403) {
                return setGenUpdate(true);
            }
        }
        return null;
    } catch (error) {
        return null;
    }
}

const CheckSaveImg = async ({userAccess, activeImages}) => {
    try {
        const response = await fetch(`https://api.betterartist.ai/api/core/art/check_saved_image/`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${userAccess}`,
            },
            body: JSON.stringify({
                image_url: activeImages
            })
        });

        if (!response.ok) {
            throw new Error(response.statusText);
        }

        const data = await response.json();

        return data;
    } catch (error) {
        console.error(error);
    }
};

function Timer() {
    const [elapsedTime, setElapsedTime] = useState(0);
  
    useEffect(() => {
        const interval = setInterval(() => {
            setElapsedTime((prevElapsedTime) => prevElapsedTime + 10);
        }, 10);
    
        return () => {
            clearInterval(interval);
        };
    }, []);
  
    const seconds = Math.floor(elapsedTime / 1000);
    const milliseconds = (elapsedTime % 1000).toString().padStart(3, '0');
  
    return (
        <div className='mt-5 ml-3 flex flex-col justify-center items-center w-[70px]'>
            <svg className="spinner" viewBox="0 0 40 40"> 
                <circle cx = "20" cy = "20" r = "18" ></circle>
            </svg>
            <div className='flex justify-center items-center gap-1'>
                <p className='text-[14px] text-green-300'>{seconds}:{milliseconds.slice(0, 2)}</p>
            </div>
        </div>
    );
}

const SameImage = () => {
    
    const { uuid } = useParams();
    const { userAccess, ws } = useUser();
    const { genSameUrl, setGenSameUrl, setGenUpdate } = useGen();
    const [images, setImages] = useState([]);
    const [activeImages, setActiveImages] = useState([]);

    useEffect(() => {
        if(userAccess) {
            fetchInitialData({uuid, userAccess, setImages, postNewData, genSameUrl, setGenUpdate});
        }
    }, [userAccess, uuid, genSameUrl]);
    
    useEffect(() => {
        if(images?.urls) {
            setActiveImages(images.urls[0]);
        }
    }, [images]);

    useEffect(() => {
        if(ws) {
            ws.addEventListener('message', (e) => {
                setImages(JSON.parse(e.data));
            })
        }
    }, [ws])

    useEffect(() => {
        if(activeImages) {
            CheckSaveImg({userAccess, activeImages})
        }
    }, [activeImages, userAccess])
    
    const navigate = useNavigate();

    const handleSamePage = (url) => {
        setGenSameUrl(url)
        navigate(`/same/${uuidv4()}`);
    }

    return (
        <div className="w-full pb-[200px] h-full md:pb-[0px]">
            <>
                {images.art_id ?
                    <div>
                        <div className="w-[80%] mx-auto flex flex-col gap-2 md:hidden">
                            <div className={`rounded-[8px] overflow-hidden border cursor-pointer border-transparent relative`}>
                                <img className="w-full h-auto" src={activeImages} alt="" />
                                
                                <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-between md:bg-[rgba(25,31,40,0.40)] z-10 rounded-lg md:rounded-2xl transition-all md:opacity-0 hover:opacity-100">
                                    <div className="w-full flex justify-end items-center p-4 md:p-[5%] gap-[10px]">
                                        <DownloadButton img={activeImages}/>
                                        <SaveButton art_id={images?.art_id} url={activeImages} />
                                    </div>
                                    <div className="p-[16px]">
                                        <button onClick={() => {handleSamePage(activeImages)}} className="text-[14px] leading-4 w-full text-[#F8FAFC] font-semibold px-4 rounded-lg bg-[#0F172A] hover:bg-[#334155] transition-all h-[40px]">
                                            Show Similar
                                        </button>
                                    </div>
                                </div>
                            </div> 

                            <div className="flex gap-2 justify-center items-center">
                                {images?.urls.slice(0, 4).map((img, index) => (
                                    <div 
                                        onClick={() => setActiveImages(img)}
                                        key={index} 
                                        className={`rounded-[8px] overflow-hidden border cursor-pointer ${activeImages === img ? 'border-[#6EE7B7]' : 'border-transparent'}`}
                                    >
                                        <img className="w-full h-auto" src={img} alt="" />
                                    </div> 
                                ))}
                            </div>
                        </div>
                        
                        <div className="w-full mx-auto hidden flex-col gap-2 md:flex">

                            <div className="flex gap-2 justify-center items-center flex-wrap md:gap-5">
                                {images?.urls.slice(0, 4).map((img, index) => (
                                    <div 
                                        onClick={() => setActiveImages(img)}
                                        key={index} 
                                        className={`rounded-2xl overflow-hidden cursor-pointer relative w-[calc(50%-20px)]`}
                                    >
                                        <img className="w-full h-auto" src={img} alt="" />
                                        <div className="absolute top-0 left-0 w-full h-full flex flex-row-reverse justify-between items-start md:bg-[rgba(25,31,40,0.40)] z-10 rounded-lg md:rounded-2xl transition-all md:opacity-0 hover:opacity-100">
                                            <div className="flex-1 flex justify-end items-center p-4 md:p-[5%] gap-[10px]">
                                                <DownloadButton img={img}/>
                                                <SaveButton art_id={images?.art_id} url={img} />
                                            </div>
                                            <div className="p-[5%] pr-0">
                                                <button onClick={() => {handleSamePage(activeImages)}} className="text-[14px] leading-6 text-[#F8FAFC] font-semibold px-4 rounded-lg bg-[#0F172A] h-[4.5vw] max-h-[50px] hover:bg-[#334155] transition-all">
                                                    <span className="hidden lg:inline">Show</span> Similar
                                                </button>
                                            </div>
                                        </div>
                                    </div> 
                                ))}
                            </div>

                        </div>


                    </div>
                    :
                    <>
                        <div className="w-[80%] mx-auto flex flex-col gap-2 md:hidden">
                            <div className='rounded-[8px] overflow-hidden border cursor-pointer border-transparent relative aspect-square'>
                                <span className="skeleton-box transition-transform transform-center block h-full rounded-2xl">
                                    <Timer />
                                </span>
                            </div>
                            <div className='flex justify-between w-full gap-2 m-auto'>
                                <div className="relative group text-primary-500 aspect-square w-[25%]">
                                    <span className="skeleton-box transition-transform transform-center block h-full rounded-2xl">
                                    </span>
                                </div>
                                <div className="relative group text-primary-500 aspect-square w-[25%]">
                                    <span className="skeleton-box transition-transform transform-center block h-full rounded-2xl">
                                    </span>
                                </div>
                                <div className="relative group text-primary-500 aspect-square w-[25%]">
                                    <span className="skeleton-box transition-transform transform-center block h-full rounded-2xl">
                                    </span>
                                </div>
                                <div className="relative group text-primary-500 aspect-square w-[25%]">
                                    <span className="skeleton-box transition-transform transform-center block h-full rounded-2xl">
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="w-full mx-auto hidden gap-5 md:flex flex-wrap">
                            <div className="relative group text-primary-500 aspect-square w-[calc(50%-20px)]">
                                <span className="skeleton-box transition-transform transform-center block h-full rounded-2xl">
                                    <Timer />
                                </span>
                            </div>
                            <div className="relative group text-primary-500 aspect-square w-[calc(50%-20px)]">
                                <span className="skeleton-box transition-transform transform-center block h-full rounded-2xl">
                                    <Timer />
                                </span>
                            </div>
                            <div className="relative group text-primary-500 aspect-square w-[calc(50%-20px)]">
                                <span className="skeleton-box transition-transform transform-center block h-full rounded-2xl">
                                    <Timer />
                                </span>
                            </div>
                            <div className="relative group text-primary-500 aspect-square w-[calc(50%-20px)]">
                                <span className="skeleton-box transition-transform transform-center block h-full rounded-2xl">
                                    <Timer />
                                </span>
                            </div>
                        </div>
                    </>
                }
            </>
        </div>
    );
}
 
export default SameImage;