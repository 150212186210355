import { useState } from "react";
import { useUser } from "../provider/UserProvider";
import { IoMdClose } from 'react-icons/io';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import Cookies from 'js-cookie';
import { GoogleLogin } from "@react-oauth/google";
import {FcGoogle} from 'react-icons/fc';

const Login = () => {
    const { setUserAccess, setAuth, setForgotPassword } = useUser();
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });
    const [error, setError] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoadin] = useState(false);

    const handleChange = (e) => {
        setError(false);
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const toggleCheckbox = () => {
        setIsChecked(!isChecked);
    };
    
    const isButtonDisabled = !formData.email || !formData.password;

    const LoginUser = async (e) => {
        e.preventDefault();
        setLoadin(true);

        try {
            const response = await fetch('https://api.betterartist.ai/api/token/pair', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });
            if (response.ok) {
                const data = await response.json();
                if(isChecked) {
                    setUserAccess(data.access)
                    Cookies.set('refresh', data.refresh);
                }else {
                    setUserAccess(data.access)
                }
                setAuth(0);
                return setLoadin(false);
            }
            setLoadin(false);
            return setError(true);
        } catch (error) {
            setLoadin(false);
            return setError(true);
        }
    }

    const login = async (credential) => {
        try {
            const response = await fetch('https://api.betterartist.ai/api/core/user/google-auth', {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: credential,
                })
            });
        
            if (response.ok) {
                const data = await response.json();
                if(isChecked) {
                    setUserAccess(data.access)
                    Cookies.set('refresh', data.refresh);
                }else {
                    setUserAccess(data.access)
                }
                setAuth(0);
            }
        } catch (error) {
            return setError(true);
        }
    }

    return (
        <div className="p-4 bg-[#1E293B] w-full md:max-w-[400px] h-screen md:h-auto md:p-10 md:rounded-[14px]">
            <form onSubmit={LoginUser} className="flex flex-col justify-between items-start" style={{'height': '-webkit-fill-available'}}>
                <div className="w-full">
                    <div className="flex w-full justify-start items-center gap-4 text-white md:flex-row-reverse md:justify-between">
                        <button className="text-[24px]" onClick={() => {setAuth(0)}}>
                            <IoMdClose />
                        </button>
                        <div className="font-bold">
                            BetterArtist.ai
                        </div>
                    </div>

                    <h3 className="text-white text-[23px] leading-[28px] font-bold my-6">Log in to Download Your Image.</h3>

                    <div className="flex flex-col gap-2 w-full mb-[18px]">
                        <div className="w-full bg-[#0F172A] rounded-lg relative input-style">
                            <input 
                                className={`rounded-lg w-full bg-transparent px-[16px] relative z-10 text-[16px] leading-6 text-white ${formData.email ? 'pt-6 pb-2' : 'pt-4 pb-4'}`} 
                                style={{'borderColor': error && '#F87171'}} 
                                type="text" 
                                name="email" 
                                value={formData.email} 
                                onChange={handleChange} 
                                required 
                            />
                            <span className={`absolute z-0 left-4 text-[#94A3B8] block ${formData.email ? 'text-[12px] top-2' : 'text-[14px] top-4'}`}>Enter email</span>
                        </div>
                        
                        <div className="w-full bg-[#0F172A] rounded-lg relative input-style">
                            <input 
                                className={`rounded-lg w-full bg-transparent px-[16px] relative z-10 text-[16px] leading-6 text-white ${formData.password ? 'pt-6 pb-2' : 'pt-4 pb-4'}`}
                                style={{'borderColor': error && '#F87171'}} 
                                type={showPassword ? 'text' : 'password'} 
                                name="password" 
                                value={formData.password} 
                                onChange={handleChange} required 
                            />
                            <span className={`absolute z-0 left-4 text-[#94A3B8] block ${formData.password ? 'text-[12px] top-2' : 'text-[14px] top-4'}`}>Password</span>
                            <div 
                                className="absolute right-4 top-4 z-20 text-[#94A3B8] text-[26px] h-[26px] w-[26px] flex justify-center items-center cursor-pointer"
                                onClick={() => {setShowPassword(!showPassword)}} 
                            >
                                {showPassword ?
                                    <AiOutlineEye />
                                    :
                                    <AiOutlineEyeInvisible />
                                }

                            </div>
                        </div>
                        {error && 
                            <div className="text-[14px] text-[#F87171]">
                                Password or Login is Invalid
                            </div>
                        }
                    </div>
                    <div className="flex w-full justify-between items-center">
                        <div className="flex gap-2 justify-center items-center">
                            <div className="square-checkbox my-auto">
                                <input
                                    type="checkbox"
                                    id="squareCheckbox"
                                    checked={isChecked}
                                    onChange={toggleCheckbox}
                                />
                                <label htmlFor="squareCheckbox"></label>
                            </div>
                            <div className="text-gray-50 text-[14px] leading-[17px] font-normal">
                                Remember Me
                            </div>
                        </div>

                        <div onClick={() => {setForgotPassword(1); setAuth(0)}} className="text-[#6EE7B7] text-[14px] leading-[20px] font-semibold cursor-pointer">
                            Forgot Password
                        </div>
                    </div>
                </div>

                <div className="flex flex-col gap-2 w-full">
                    <button 
                        type="submit" 
                        disabled={isButtonDisabled || loading} 
                        className={`mt-4 text-[14px] leading-[28px] w-full py-2 px-4 bg-green-300 rounded-[8px] text-gray-900 font-semibold ${isButtonDisabled && 'opacity-30 cursor-not-allowed'}`}
                    >
                        {loading ? 
                            <div className="flex justify-center items-center">
                                <div className="w-[28px] h-[28px] rounded-full animate-spin border-2 border-solid border-gray-900 border-t-transparent"></div>
                            </div>
                            :
                            'Log In'
                        }
                    </button>

                    <div className="hidden w-full md:flex relative justify-center items-center my-6">
                        <div className="relative z-[1] bg-gray-800 px-4 text-[14px] text-gray-50 font-normal">or</div>
                        <span className="w-full absolute h-[1px] bg-[#F2F2F2] z-[0]"></span>
                    </div>

                    <div className="relative">
                        <div className="absolute top-0 left-0 opacity-0 z-10 h-full hover-google">
                            <GoogleLogin
                                onSuccess={credentialResponse => {
                                    login(credentialResponse.credential)
                                }}
                                onError={() => {
                                }}
                                width={360}
                                shape={'square'}
                            />
                        </div>
                        <button className="google-btn relative z-0 flex gap-2 border border-[#E2E8F0] rounded-lg justify-center items-center w-full p-3 text-[14px] text-gray-50 hover:border-[#6EE7B7] hover:text-[#6EE7B7] font-semibold leading-[18px]">
                            <FcGoogle className="text-[18px]" />Continue with Google
                        </button>
                    </div>
                    <button 
                        onClick={() => {setAuth(2)}} 
                        className='mt-4 text-gray-50 text-[14px] font-semibold text-center w-full'
                    >
                        Don’t have an account? 
                        <span className='text-green-300 ml-1'>Create an Account.</span>
                    </button>
                </div>
            </form>
        </div>
    );
}
 
export default Login;