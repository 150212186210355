import { Link, useParams } from "react-router-dom";
import { CiSearch } from "react-icons/ci";
import { VscFilter } from "react-icons/vsc";
import Saved from "./saved/Saved";
import { useFilter } from "../../provider/filterProvider";
import { useEffect, useRef, useState } from "react";
import { IoMdClose } from "react-icons/io";
import History from "./history/History";
import Explore from "./explore/Explore";
import InputDashboard from "../../component/ui/InputDashboard";
 
const Dashboard = () => {
    const { filterText, setFilterText, filterDate, setFilterDate, filterShow } = useFilter();
    
    const [filterDateBlock, setFilterDateBlock] = useState(false);
    const [inputFocus, setInputFocus] = useState(false);
    
    const { page } = useParams();

    const navPages = [
        {
            name: 'Explore',
            type: 'explore'
        },
        {
            name: 'Saved Images',
            type: 'saved'
        },
        {
            name: 'Prompt History',
            type: 'history'
        }
    ]

    const inputRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (inputRef.current && !inputRef.current.contains(event.target)) {
                setInputFocus(false);
                setFilterDateBlock(false);
            }
        };
    
        if (inputFocus || filterDateBlock) {
            document.addEventListener('click', handleClickOutside);
        } else {
            document.removeEventListener('click', handleClickOutside);
        }
    
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [inputFocus, filterDateBlock]);

    return (
        <>
            <div className={`flex w-full justify-between items-center gap-4 ${page === 'explore' ? 'p-0 md:px-10 md:pb-6 md:pt-4' : 'px-4 py-4 md:px-10 md:pb-6 md:pt-4'}`}>
                <div className="hidden md:flex gap-6 items-center justify-start">
                    {navPages.map((tab, index) => (
                        <Link key={index} to={`/dashboard/${tab.type}`} onClick={() => {setFilterDate(''); setFilterText('');}} className={`text-[24px] leading-6 font-bold whitespace-nowrap ${page === tab.type ? 'text-[#F8FAFC]' : 'text-[#64748B]'}`}>
                            {tab.name}
                        </Link>
                    ))}
                </div>
                <div className="w-full md:w-[-webkit-fill-available] md:max-w-[480px] flex flex-col relative justify-end md:h-[46px]">
                    {page !== 'explore' && 
                        <>
                            <div ref={inputRef} className={`w-full bg-[#0F172A] rounded-lg ${filterDateBlock || inputFocus ? 'rounded-lg md:rounded-b-[0]' : 'rounded-lg'} relative md:max-w-[480px] md:w-[100%]`}>
                                <CiSearch className="absolute z-0 top-2 left-2 text-[#94A3B8] text-[22px] md:top-[11px] md:text-[24px]"/>
                                <input
                                    onChange={(event) => setFilterText(event.target.value)}
                                    onClick={() => {setFilterDateBlock(false)}}
                                    onFocus={() => {setInputFocus(true)}}
                                    value={filterText}
                                    className={`relative z-10 py-2 px-[34px] md:py-[11px] md:px-[40px] w-full md:w-[100%] ${filterDateBlock || inputFocus ? 'rounded-lg md:rounded-b-[0]' : 'rounded-lg'} bg-transparent text-[#ffffff] transition-all placeholder:text-[#94A3B8] text-[14px] leading-[22px] border border-[#0F172A] md:text-[18px] md:leading-[22px] focus:border-[#6EE7B7]`} 
                                    type="text" 
                                />
                                <div className={`overflow-hidden absolute z-20 top-0 right-0 py-[9px] px-2 md:py-[11px]`} onClick={() => {setFilterDateBlock(!filterDateBlock); setInputFocus(false);}}>
                                    <VscFilter className=" text-gray-400 text-[20px] cursor-pointer md:text-[24px]"/>
                                </div>
                                <div className={`absolute top-[calc(100%+10px)] md:top-[100%] z-50 w-full bg-[#0F172A] overflow-y-auto flex flex-col gap-2 rounded-lg md:rounded-t-[0] transition-all ${inputFocus ? 'max-h-[50vh] h-auto p-4' : 'h-[0vh] p-0'}`}>
                                    {filterShow.length === 0 ?
                                        <div className="text-[14px] leading-4 text-[#F8FAFC] font-bold">
                                            No Results
                                        </div>
                                        :
                                        <div className="text-[14px] leading-4 text-[#F8FAFC] font-bold">Recents</div>
                                    }
                                    {filterShow.map((item, index) => (
                                        <div onClick={() => {if(item?.art?.prompt){setFilterText(item?.art?.prompt);}else if(item?.prompt){setFilterText(item?.prompt);} setInputFocus(false);}} key={index} className="cursor-pointer w-full p-3 rounded-md bg-[#334155]/50 flex justify-between gap-3 items-center">
                                            {item?.image_url && 
                                                <img className="w-[40px] h-auto rounded-md" src={item?.image_url} alt="" />
                                            }
                                            <p className="truncate overflow-ellipsis flex-1 text-[14px] leading-4 text-[#F8FAFC] font-normal">{item?.art?.prompt} {item?.prompt}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            {filterDateBlock &&
                                <div className={`relative top-[100%] z-40 left-0 md:absolute flex mt-2 md:mt-0 gap-2 overflow-y-auto md:bg-[#0F172A] md:p-2 md:w-full ${filterDateBlock ? 'rounded-lg md:rounded-t-[0] md:border-t md:border-[#64748B]' : 'rounded-lg'}`}>
                                    <div 
                                        onClick={() => {setFilterDate('day'); setFilterDateBlock(!filterDateBlock)}}
                                        className={`border border-[#475569] rounded-lg py-[6px] px-2 text-[12px] whitespace-nowrap font-semibold ${filterDate === 'day' ? 'bg-[#F8FAFC] text-[#0F172A]' : 'text-[#F8FAFC]'}`}
                                    >
                                        Today
                                    </div>
                                    <div
                                        onClick={() => {setFilterDate('lastday'); setFilterDateBlock(!filterDateBlock)}}
                                        className={`border border-[#475569] rounded-lg py-[6px] px-2 text-[12px] whitespace-nowrap font-semibold ${filterDate === 'lastday' ? 'bg-[#F8FAFC] text-[#0F172A]' : 'text-[#F8FAFC]'}`}
                                    >
                                        Yesterday
                                    </div>
                                    <div
                                        onClick={() => {setFilterDate('lastweek'); setFilterDateBlock(!filterDateBlock)}}
                                        className={`border border-[#475569] rounded-lg py-[6px] px-2 text-[12px] whitespace-nowrap font-semibold ${filterDate === 'lastweek' ? 'bg-[#F8FAFC] text-[#0F172A]' : 'text-[#F8FAFC]'}`}
                                    >
                                        Last Week
                                    </div>
                                    <div
                                        onClick={() => {setFilterDate('lastmonth'); setFilterDateBlock(!filterDateBlock)}}
                                        className={`border border-[#475569] rounded-lg py-[6px] px-2 text-[12px] whitespace-nowrap font-semibold ${filterDate === 'lastmonth' ? 'bg-[#F8FAFC] text-[#0F172A]' : 'text-[#F8FAFC]'}`}
                                    >
                                        Last 28 days
                                    </div>
                                </div>
                            }
                        </>
                    }
                </div>
            </div>
            {(!filterDateBlock && filterDate) &&
                <div className={`flex w-full justify-between items-center gap-4 ${page === 'explore' ? 'p-0 md:px-10 md:pb-0' : 'px-4 pb-4 md:px-10 md:pb-4'}`}>
                        <div className="relative flex gap-2 overflow-y-auto">
                            {filterDate && 
                                <div 
                                    onClick={() => {setFilterDate(''); setFilterDateBlock(false)}}
                                    className={`cursor-pointer flex gap-1 items-center border border-[#475569] rounded-lg py-[6px] px-2 text-[12px] whitespace-nowrap font-semibold bg-[#F8FAFC] text-[#0F172A]`}
                                >
                                    {filterDate === 'day' ? 'Today' : filterDate === 'lastday' ? 'Yesterday' : filterDate === 'lastweek' ? 'Last Week' : filterDate === 'lastmonth' ? 'Last 28 days' : ''}
                                    <IoMdClose className="text-[18px]" />
                                </div>
                            }
                        </div>
                </div>
            }
            <>
                {page === "explore" ? (
                    <div className="w-full mx-auto md:px-6 mb-[200px] md:mb-[120px]">
                        <Explore />
                    </div>
                ) : page === "saved" ? (
                    <div className="w-full mx-auto md:px-6 mb-[200px] md:mb-[120px]">
                        <Saved />
                    </div>
                ) : page === "history" ? (
                    <div className="w-full mx-auto md:px-6 mb-[200px] md:mb-[120px]">
                        <History />
                    </div>
                ) : null
                }
                <div className="fixed bottom-0 md:bottom-4 left-0 md:left-[60px] w-full md:w-[calc(100vw-120px)] z-[100]">
                    <InputDashboard />
                </div>
            </>
        </>
    );
}
 
export default Dashboard;