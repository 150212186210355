import { Link, useNavigate } from 'react-router-dom';
import Bg from '../../assets/image/Bg.png';
import { BiArrowBack } from "react-icons/bi";
import { BsCheckLg } from "react-icons/bs";
import { MdOutlineClose } from "react-icons/md";
import { useUser } from '../../provider/UserProvider';

const Upgrade = () => {
    const { userData } = useUser();
    const navigate = useNavigate();

    const plans = [
        {
            popular: false,
            name: 'Standard',
            statusButton: false,
            priceId: 'price_1NwmvWFnxYkE1MAqi7HndM4j',
            price: '5',
            points: [
                {
                    included: true,
                    name: '1 Team Member'
                },
                {
                    included: true,
                    name: '100 Generations'
                },
                {
                    included: true,
                    name: 'Download JPG'
                },
                {
                    included: false,
                    name: 'Download PNG'
                },
                {
                    included: false,
                    name: 'Custom Landing Pages'
                }
            ]
        },
        {
            popular: true,
            name: 'Advanced',
            statusButton: false,
            priceId: 'price_1NwmwQFnxYkE1MAq24tnYiEJ',
            price: '15',
            points: [
                {
                    included: true,
                    name: '2 Team Members'
                },
                {
                    included: true,
                    name: '500 Generations'
                },
                {
                    included: true,
                    name: 'Download JPG'
                },
                {
                    included: true,
                    name: 'Download PNG'
                },
                {
                    included: false,
                    name: 'Custom Landing Pages'
                }
            ]
        },
        {
            popular: false,
            name: 'Professional',
            statusButton: false,
            priceId: 'price_1NwmwsFnxYkE1MAqIYb4d2Ct',
            price: '25',
            points: [
                {
                    included: true,
                    name: '5 Team Members'
                },
                {
                    included: true,
                    name: 'Unlimited Generations'
                },
                {
                    included: true,
                    name: 'Download JPG'
                },
                {
                    included: true,
                    name: 'Download PNG'
                },
                {
                    included: true,
                    name: 'Custom Landing Pages'
                }
            ]
        }
    ]
    
    const onSelectPlan = (plan) => {
        plan.statusButton = "Loading...";
        if(userData) {
            navigate(`/checkout/${plan.name}/${plan.priceId}`);
        }
    }


    return (  
        <div className="fixed w-screen h-screen top-0 left-0">
            <div className='absolute top-0 left-0 z-[1] h-full w-full overflow-hidden'>
                <div className='min-h-full min-w-full absolute z-[1]' style={{background: 'linear-gradient(to bottom, transparent 0%, transparent 80%, #0F172A 100%)'}}></div>
                <img src={Bg} alt='' className='min-h-full min-w-full relative z-0' />
            </div>
            <div className="relative z-10 py-6 px-10 flex justify-between items-center border-b-[1px] border-gray-800 max-lg:py-3 max-lg:px-4 ">
                <div onClick={() => {navigate(-1)}}>
                    <BiArrowBack className=" text-gray-400 text-[30px] cursor-pointer hidden md:block" />
                </div>

                <div>
                    <Link to={'/'} className="text-[23px] font-extrabold uppercase text-gray-50 max-lg:text-[18px]">
                        Revel
                        <span className="text-green-300">8</span>
                    </Link>
                </div>

                <div className='w-6'></div>
            </div>

            <div className='relative z-10 w-full flex flex-col justify-center items-center max-w-[1440px] m-auto px-[120px] h-[inherit] scroll-small max-lg:p-4 max-lg:items-start max-lg:pt-6 max-lg:overflow-auto max-lg:justify-start max-lg:pb-[150px]'>
                <div className='text-[33px] text-gray-50 leading-10 font-extrabold mb-12 max-lg:text-[23px] max-lg:leading-[28px] max-lg:font-bold max-lg:mb-6'>Upgrade Plans</div>
                <div className='w-full flex justify-center items-end gap-6 max-lg:flex-col max-lg:items-center'>
                    {plans.map((plan) => (
                        <div className={`flex-1 w-1/3 border rounded-[14px] p-6 ${plan.popular ? 'border-green-300 bg-green-300/20' : 'border-gray-700'} max-lg:w-full`} key={plan.name}>
                            {plan.popular && 
                                <div className='w-full text-center text-green-300 text-[12px] leading-[18px] font-semibold mb-6'>Most Popular</div>
                            }
                            <div className='text-[23px] text-gray-50 leading-[28px] font-bold mb-4'>
                                {plan.name} Plan
                            </div>
                            <div className='text-[34px] text-gray-50 leading-[40px] font-bold'>
                                {plan.price}
                                <span className='text-[28px] leading-[34px]'>
                                    $/month
                                </span>
                            </div>
                            <div className='flex flex-col gap-4 mt-6 mb-[72px]'>
                                {plan.points.map((point) => (
                                    <div key={point.name} className='flex gap-2 items-center'>
                                        <div className={`w-[24px] h-[24px] rounded-full flex justify-center items-center border-2 ${point.included ? 'bg-green-300 border-green-300 text-gray-900' : 'border-gray-50/50 text-gray-50/50'}`}>
                                            {point.included ?
                                                <BsCheckLg />
                                                :
                                                <MdOutlineClose />
                                            }
                                        </div>
                                        {point.included ?
                                            <div className='text-[14px] text-gray-50 leading-[17px] font-normal'>
                                                {point.name}
                                            </div>
                                            :
                                            <div className='text-[14px] text-gray-50/50 leading-[17px] font-normal line-through'>
                                                {point.name}
                                            </div>
                                        }
                                    </div>
                                ))}
                            </div>
                            {!plan.statusButton ?
                                <button 
                                    onClick={() => onSelectPlan(plan)}
                                    className="w-full bg-green-300 hover:[#10B981] transition-all rounded-[5px] p-5 text-[18px] text-gray-900 leading-[22px] font-semibold"
                                >
                                    Choose Plan
                                </button>
                                :
                                <button
                                    className="w-full bg-green-300 rounded-[5px] p-5 text-[18px] text-gray-900 leading-[22px] font-semibold"
                                >
                                    Loading...
                                </button>
                            }
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
 
export default Upgrade;