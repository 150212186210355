import { useEffect, useState } from "react";
import { PiHeart, PiHeartFill } from "react-icons/pi";
import { useUser } from "../../provider/UserProvider";


const SaveButton = ({art_id, url}) => {
    const { userAccess } = useUser();
    const [checkImg, setCheckImg] = useState(false)
    const [checkLoading, setCheckLoading] = useState(false)
    const postSaveImg = async (art_id, url) => {
        if(!checkLoading) {
            setCheckLoading(true);
            try {
                const response = await fetch('https://api.betterartist.ai/api/core/art/saved_images', {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${userAccess}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        url: url,
                        art_id: art_id,
                    }),
                });
            
                if (!response.ok) {
                    return null
                }
            
                CheckSaveImg(url);
            } catch (error) {
                console.error(error);
                setCheckLoading(false);
            }
        }
    };

    const postDeleteImg = async (id) => {
        if(!checkLoading) {
            setCheckLoading(true);
            try {
                const response = await fetch(`https://api.betterartist.ai/api/core/art/saved_images/${id}`, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `Bearer ${userAccess}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        id: id
                    }),
                });
            
                if (!response.ok) {
                    return null
                }
            
                CheckSaveImg(url);
            } catch (error) {
                console.error(error);
                setCheckLoading(false);
            }
        }
    };

    const CheckSaveImg = async (url) => {
        try {
            const response = await fetch(`https://api.betterartist.ai/api/core/art/check_saved_image/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${userAccess}`,
                },
                body: JSON.stringify({
                    image_url: url
                })
            });
    
            if (!response.ok) {
                throw new Error(response.statusText);
            }
    
            const data = await response.json();
            setCheckLoading(false);
            setCheckImg(data);
        } catch (error) {
            setCheckLoading(false)
            console.error(error);
        }
    };

    useEffect(() => {
        CheckSaveImg(url)
    }, [url])

    return (  
        <>
            {checkLoading ?
                <button className={`h-[40px] w-[40px] md:h-[4.5vw] md:w-[4.5vw] md:max-h-[50px] md:max-w-[50px] rounded-full border flex justify-center items-center text-2xl border-[#6EE7B7] text-[#6EE7B7]`}>
                    <div className="w-[28px] h-[28px] rounded-full animate-spin border-2 border-solid border-[#6EE7B7] border-t-transparent"></div>
                </button>
                :
                <>
                    {checkImg?.exist ?
                        <button onClick={() => {postDeleteImg(checkImg?.id)}} className={`h-[40px] w-[40px] md:h-[4.5vw] md:w-[4.5vw] md:max-h-[50px] md:max-w-[50px] rounded-full border flex justify-center items-center text-2xl md:text-[26px] border-[#6EE7B7] text-[#6EE7B7]`}>
                            <PiHeartFill />
                        </button>
                        :
                        <button onClick={() => {postSaveImg(art_id, url)}} className={`h-[40px] w-[40px] md:h-[4.5vw] md:w-[4.5vw] md:max-h-[50px] md:max-w-[50px] rounded-full border flex justify-center items-center text-2xl md:text-[26px] border-[#F8FAFC] text-[#F8FAFC]`}>
                            <PiHeart />
                        </button>
                    }
                </>
            }
        </>
    );
}
 
export default SaveButton;