import { useEffect, useRef, useState } from 'react';
import { useUser } from '../../provider/UserProvider';
import styled from 'styled-components';
import { AiOutlineUser } from 'react-icons/ai';
import { IoSettingsOutline } from "react-icons/io5";
import { LiaUserSolid } from "react-icons/lia";
import { MdOutlineLogout } from "react-icons/md";
import { FiArrowUpRight } from "react-icons/fi";
import Cookies from 'js-cookie';
import { Link, useNavigate } from 'react-router-dom';
import PasswordChange from '../../auth/PasswordChange/PasswordChange';

const NavBar = () => {
    const [open, setOpen] = useState(false);
    const { userData, userAccess, setAuth, setUserAccess } = useUser();
    const [dropdown, setDropdown] = useState(false); 
    const navigation = useNavigate();
    const [passwordChange, setPasswordChange] = useState(false);
    const [tab, setTab] = useState(false);
    
    const node = useRef();
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdown(false);
            }
        };
    
        if (dropdown) {
            document.addEventListener('click', handleClickOutside);
        } else {
            document.removeEventListener('click', handleClickOutside);
        }
    
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [dropdown]);

    return (
        <div className="w-full flex flex-row-reverse justify-between items-center p-4 md:flex-row md:py-6 md:px-10 border-b md:border-none border-[#334155]">
            <div className='w-[20px] md:hidden'>
                
            </div>
            <Link to={`/`} className='text-white font-bold'>BetterArtist.ai</Link>

                <>  
                    <div ref={node} className="flex md:hidden">
                        <Burger open={open} setOpen={setOpen} />
                        <Menu open={open} setOpen={setOpen} setUserAccess={setUserAccess} setAuth={setAuth} userAccess={userAccess} userData={userData} passwordChange={passwordChange} setPasswordChange={setPasswordChange} tab={tab} setTab={setTab} />
                    </div>
                    {userAccess ?
                        <>
                            <div className='hidden md:flex gap-6' ref={dropdownRef}>
                                <div className='flex gap-2'>
                                    <Link
                                        to={`/upgrade`}
                                        className='bg-[#F8FAFC] border border-[#F8FAFC] hover:text-[#0F172A] hover:bg-[#CBD5E1] hover:border-[#CBD5E1] transition-all py-2 px-3 rounded-md text-[12px] text-[#0F172A] font-semibold'
                                    >
                                        Upgrade
                                    </Link>
                                    <Link
                                        className='border rounded-[5px] leading-[18px] text-[#F8FAFC] font-semibold border-[#94A3B8] hover:border-[#F8FAFC] hover:text-[#0F172A] hover:bg-[#F8FAFC]  bg-transparent flex gap-[6px] items-center justify-center px-3'
                                        to={`/dashboard/saved`}
                                    >
                                        <div className='text-[12px]'>
                                            Go to Dashboard
                                        </div>
                                        <FiArrowUpRight />
                                    </Link>
                                </div>
                                {userData?.image ?
                                    <div 
                                        onClick={() => {setDropdown(!dropdown)}}
                                        className='flex gap-2 items-center justify-start w-auto cursor-pointer'
                                    >
                                        <img src={userData?.image} alt="" className='w-[32px] h-[32px] rounded-full' />
                                        <p className='text-[#F8FAFC] text-[16px] font-semibold'>{userData?.email.substring(0, userData?.email.indexOf('@'))}</p>
                                    </div>
                                    :
                                    <div 
                                        onClick={() => {setDropdown(!dropdown)}}
                                        className='flex gap-2 items-center justify-start w-auto cursor-pointer'
                                    >
                                        <div className='w-[32px] h-[32px] rounded-full bg-[#fff] flex justify-center items-center'> 
                                            <AiOutlineUser className='text-[24px] text-[#0F172A]' />
                                        </div>
                                        <p className='text-[#F8FAFC] text-[16px] font-semibold'>{userData?.email.substring(0, userData?.email.indexOf('@'))}</p>
                                    </div>
                                }
                                <div className={`absolute top-[67px] bg-[#FFF] rounded-[8px] flex flex-col overflow-hidden transition-all whitespace-nowrap ${dropdown ? 'z-50 w-[200px] right-[20px]' : 'z-[-2] w-[0px] right-[120px]'}`}>
                                    <button onClick={() => {setPasswordChange(true); setTab(false); setDropdown(!dropdown)}} className={` text-[#000000] hover:bg-[#F1F5F9] font-semibold text-left flex gap-2 items-center justify-start transition-all ${dropdown ? 'p-4 leading-[24px] text-[14px]' : 'p-0 leading-[0px] text-[0px]'}`}>
                                        <IoSettingsOutline />
                                        Account Settings
                                    </button>
                                    <button onClick={() => {setPasswordChange(true); setTab(true); setDropdown(!dropdown)}} className={` text-[#000000] hover:bg-[#F1F5F9] font-semibold text-left flex gap-2 items-center justify-start transition-all ${dropdown ? 'p-4 leading-[24px] text-[14px]' : 'p-0 leading-[0px] text-[0px]'}`}>
                                        <LiaUserSolid />
                                        Manage Billing
                                    </button>
                                    <button onClick={()=> {setDropdown(!dropdown); Cookies.remove('refresh'); setUserAccess(null); navigation(`/`)}} className={` text-[#000000] hover:bg-[#F1F5F9] font-semibold text-left flex gap-2 items-center justify-start transition-all ${dropdown ? 'p-4 leading-[24px] text-[14px]' : 'p-0 leading-[0px] text-[0px]'}`}>
                                        <MdOutlineLogout />
                                        Log Out
                                    </button>
                                </div>
                            </div>
                        </>
                    :
                        <div className="hidden md:flex gap-2">
                            <button 
                                className='border border-[#F8FAFC] hover:text-[#0F172A] hover:bg-[#F8FAFC] transition-all py-2 px-3 rounded-md text-[12px] text-[#F8FAFC] font-semibold'
                                onClick={()=>{setAuth(1)}}
                            >Log in</button>
                            <button 
                                className='bg-[#F8FAFC] border border-[#F8FAFC] hover:text-[#0F172A] hover:bg-[#CBD5E1] hover:border-[#CBD5E1] transition-all py-2 px-3 rounded-md text-[12px] text-[#0F172A] font-semibold'
                                onClick={()=>{setAuth(2)}}
                            >Sign Up</button>
                        </div>
                    }
                    
                    {passwordChange &&
                        <PasswordChange setTab={setTab} tab={tab} setPasswordChange={setPasswordChange} />
                    }
                </>
        </div>
    );
}

const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: #0F172A;
  transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-100%)'};
  height: 100vh;
  text-align: left;
  padding: 16px;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  z-index: 51;
  width: 400px;

  @media (max-width: 576px) {
      width: 90%;
    }
`

const Menu = ({ open, setOpen, setUserAccess, setAuth, userAccess, userData, passwordChange, setPasswordChange, tab, setTab }) => {
    
    const navigation = useNavigate();
    const navPages = [
        {
            name: 'Explore',
            type: 'explore'
        },
        {
            name: 'Saved Images',
            type: 'saved'
        },
        {
            name: 'Prompt History',
            type: 'history'
        }
    ]
    const [dropdown, setDropdown] = useState(false); 
    const dropdownRef = useRef(null);
    
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdown(false);
            }
        };
    
        if (dropdown) {
            document.addEventListener('click', handleClickOutside);
        } else {
            document.removeEventListener('click', handleClickOutside);
        }
    
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [dropdown]);

    const currentLocation = window.location.pathname.split('/')[2];

    return (
        <>
            <StyledMenu open={open}>
                {userAccess ?
                    <div 
                        className='flex flex-col justify-between'
                        style={{'height': '-webkit-fill-available'}}
                    >
                        <div>
                            {userData?.image ?
                                <div className='flex gap-2 items-center justify-start w-[calc(100%-30px)]'>
                                    <img src={userData?.image} alt="" className='w-[32px] h-[32px] rounded-full' />
                                    <p className='text-[#F8FAFC] text-[16px] font-semibold'>{userData?.email.substring(0, userData?.email.indexOf('@'))}</p>
                                </div>
                                :
                                <div className='flex gap-2 items-center justify-start w-[calc(100%-30px)]'>
                                    <div className='w-[32px] h-[32px] rounded-full bg-[#fff] flex justify-center items-center'> 
                                        <AiOutlineUser className='text-[24px] text-[#0F172A]' />
                                    </div>
                                    <p className='text-[#F8FAFC] text-[16px] font-semibold'>{userData?.email.substring(0, userData?.email.indexOf('@'))}</p>
                                </div>
                            }
                            <div className='flex flex-col gap-[10px] mt-7'>
                                {navPages.map((tab) => (
                                    <Link onClick={() => {setOpen(!open)}} key={tab.type} to={`/dashboard/${tab.type}`} className={`text-[14px] font-semibold px-4 py-3 w-full rounded-[5px] leading-[18px] ${currentLocation === tab.type ? 'text-gray-900 bg-gray-50' : 'text-gray-50 bg-transparent'}`}>
                                        {tab.name}
                                    </Link>
                                ))}
                                <Link onClick={() => {setOpen(!open)}} to={`/`} className={`text-[14px] font-semibold px-4 py-3 w-full rounded-[5px] leading-[18px] ${currentLocation === undefined ? 'text-gray-900 bg-gray-50' : 'text-gray-50 bg-transparent'}`}>
                                    Leading
                                </Link>
                            </div>
                        </div>
                        <div className='flex flex-col gap-[10px]' ref={dropdownRef}>
                            <div className={`absolute bottom-[107px] bg-[#F8FAFC] rounded-[8px] flex flex-col overflow-hidden transition-all whitespace-nowrap ${dropdown ? 'z-50 w-[200px] left-[16px]' : 'z-[-2] w-[0px] left-[120px]'}`}>
                                <button onClick={() => {setPasswordChange(true); setTab(false); setOpen(false)}} className={` text-[#0F172A] font-semibold text-left flex gap-2 items-center justify-start transition-all ${dropdown ? 'p-4 leading-[24px] text-[14px]' : 'p-0 leading-[0px] text-[0px]'}`}>
                                    <IoSettingsOutline />
                                    Account Settings
                                </button>
                                <button onClick={() => {setPasswordChange(true); setTab(true); setOpen(false)}} className={` text-[#0F172A] font-semibold text-left flex gap-2 items-center justify-start transition-all ${dropdown ? 'p-4 leading-[24px] text-[14px]' : 'p-0 leading-[0px] text-[0px]'}`}>
                                    <LiaUserSolid />
                                    Manage Billing
                                </button>
                                <button onClick={()=> {setDropdown(!dropdown); Cookies.remove('refresh'); setUserAccess(null); navigation(`/`)}} className={` text-[#0F172A] font-semibold text-left flex gap-2 items-center justify-start transition-all ${dropdown ? 'p-4 leading-[24px] text-[14px]' : 'p-0 leading-[0px] text-[0px]'}`}>
                                    <MdOutlineLogout />
                                    Log Out
                                </button>
                            </div>
                            <div 
                                onClick={() => {setDropdown(!dropdown)}}
                                className='bg-[#F8FAFC] cursor-pointer border border-[#F8FAFC] text-center py-2 px-3 rounded-md text-[12px] text-[#0F172A] font-semibold flex gap-2 items-center justify-center'
                            >
                                <IoSettingsOutline />
                                Account Settings
                            </div>
                            <Link
                                to={`/upgrade`}
                                onClick={() => {setOpen(!open)}}
                                className='bg-[#fcd34d] border border-[#fcd34d] text-center py-2 px-3 rounded-md text-[12px] text-[#0F172A] font-semibold'
                            >
                                Upgrade
                            </Link>
                        </div>
                    </div>
                :
                    <> 
                        <Link to={`/`} className='absolute top-4 left-4 text-white font-bold'>BetterArtist.ai</Link>
                        <div className="flex flex-col gap-4 mt-4">
                            <button 
                                className='w-full border border-[#F8FAFC] hover:text-[#0F172A] hover:bg-[#F8FAFC] transition-all py-2 px-3 rounded-md text-[12px] text-[#F8FAFC] font-semibold'
                                onClick={()=>{setAuth(1); setOpen(!open)}}
                            >Log in</button>
                            <button 
                                className='w-full bg-[#F8FAFC] border border-[#F8FAFC] hover:text-[#0F172A] hover:bg-[#CBD5E1] hover:border-[#CBD5E1] transition-all py-2 px-3 rounded-md text-[12px] text-[#0F172A] font-semibold'
                                onClick={()=>{setAuth(2); setOpen(!open)}}
                            >Sign Up</button>
                        </div>
                    </>
                }
            </StyledMenu>
            <div onClick={() => {setOpen(!open)}} className={`w-full h-screen fixed top-0 left-0 transition ${open ? 'bg-[#ffffff]/5 z-[1000]' : 'bg-transparent z-[-2]'}`}>
            </div>
        </>
    )
}

const StyledBurger = styled.button`
  position: absolute;
  top: 16px;
  ${({ open }) => open ? 'left: calc(400px - 40px);' : 'left: 16px;'}
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 24px;
  height: 24px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 52;
  transition: all 0.3s ease-in-out;

  @media (max-width: 576px) {
    ${({ open }) => open ? 'left: calc(90% - 40px);' : 'left: 16px;'}
  }

  &:focus {
    outline: none;
  }

  div {
    width: 24px;
    height: 3px;
    background: ${({ open }) => open ? '#ffffff' : '#ffffff'};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
  }
  div:first-child {
    transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
  }

  div:nth-child(2) {
    opacity: ${({ open }) => open ? '0' : '1'};
    transform: ${({ open }) => open ? 'translateX(20px)' : 'translateX(0)'};
  }

  div:nth-child(3) {
    transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
  }
`

const Burger = ({ open, setOpen }) => {
  return (
    <StyledBurger open={open} onClick={() => setOpen(!open)}>
      <div />
      <div />
      <div />
    </StyledBurger>
  )
}
 
export default NavBar;