import { createContext, useContext, useEffect, useState } from 'react';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userAccess, setUserAccess] = useState(null);
  const [userData, setUserData] = useState(null);
  const [auth, setAuth] = useState(0);
  const [ws, setWs] = useState(null);
  const [forgotPassword, setForgotPassword] = useState(0);

  useEffect(() => {
    if(userAccess) {
      async function userData () {
        const userMe = await fetch('https://api.betterartist.ai/api/core/user/me', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${userAccess}`,
          },
        });

        if (!userMe.ok) {
          throw new Error('Помилка при виконанні GET запиту');
        }

        const data = await userMe.json();

        setUserData(data);

        const ws = new WebSocket(`wss://art-generator-utpn6iuzbq-uc.a.run.app/core/ws/?token=${userAccess}`);
    
        ws.addEventListener('open', () => {
        });
  
        ws.addEventListener('message', (event) => {
        });
  
        ws.addEventListener('close', (event) => {
        });
  
        ws.addEventListener('error', (error) => {
        });
        
        setWs(ws);
      }

      userData();
    }else {
      setWs(null);
      setUserData(null);
    }
  }, [userAccess]);
  return (
    <UserContext.Provider value={{ userAccess, setUserAccess, auth, setAuth, ws, setWs, userData, setUserData, forgotPassword, setForgotPassword }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  return useContext(UserContext);
};