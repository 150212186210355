import Input from "../component/ui/Input";
import img1 from '../assets/image/exploreImg/609c7272-6e8a-4279-92b0-f4a03b0d45c7-0.webp';
import img2 from '../assets/image/exploreImg/02aa5ef9-7b45-413d-bf36-5b39a83cc9ae-1.png';
import img3 from '../assets/image/exploreImg/55057dac-fd48-4f23-a0e1-b70cc8f34d4d-3.webp';
import img4 from '../assets/image/exploreImg/2b8d80fd-a7cb-4408-9622-b859123276cc-3.png';
import img5 from '../assets/image/exploreImg/542b11c1-6896-43c9-a8ce-2cca4f1a7988-2.webp';
import img6 from '../assets/image/exploreImg/a0dfd7a7-ce3e-4fc9-9f29-7d082270085a-3.webp';
import img7 from '../assets/image/exploreImg/947166ff-f82d-4f6d-94de-7ee74a369eef-0.png';
import img8 from '../assets/image/exploreImg/1bf67604-2779-473f-b13b-de459d7e3f6a-0.webp';
import img9 from '../assets/image/exploreImg/2372bafb-a09b-4797-a045-27725461eb50-3.png';
import { useGen } from "../provider/genProvider";
import { Link, useNavigate } from "react-router-dom";
import { useUser } from "../provider/UserProvider";
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Landing = () => {
    const { setGenPromp, setGenStyle, setGenSize } = useGen();
    const [resetPasswordModalContent, setResetPasswordModalContent] = useState(false);
    const [resetPasswordModal, setResetPasswordModal] = useState(false);
    const [error, setError] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [oldShowPassword, setOldShowPassword] = useState(false);
    
    const [formData, setFormData] = useState({
        password1: '',
        password2: '',
    });
    
    const [loading, setLoadin] = useState(false);
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        setError(false);
    };
    
    const isButtonDisabled = !formData.password1 || !formData.password2;

    const navigate = useNavigate();
    
    let query = useQuery();
    let token = query.get('token');

    useEffect(() => {
        if(token !== null) {
            setResetPasswordModal(true);
            CheckToken()
        }
    }, [token])

    const CheckToken = async () => {
        try {
            const response = await fetch('https://api.betterartist.ai/api/core/user/check-reset-token/' + token, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
        
            if (response.ok) {
                const data = await response.json();
                if(data === false) {
                    navigate(`/`)
                    setResetPasswordModal(false);
                    setResetPasswordModalContent(false);
                }else {
                    setResetPasswordModalContent(true);
                }
            }
        } catch (error) {
            return null;
        }
    }

    const { userAccess, setAuth } = useUser();
    const exploreData = [
        {
            img: img1,
            prompt: 'Mystical moonlit forest with shimmering lake',
            size: '1:1',
            sizeName: 'Square',
            style: 'Cyberpunk'
        },
        {
            img: img2,
            prompt: 'City skyline at twilight with vibrant colors and reflection',
            size: '16:9',
            sizeName: 'Widescreen',
            style: 'Oil'
        },
        {
            img: img3,
            prompt: 'Majestic aurora illuminates snowy mountain peak at twilight',
            size: '3:4',
            sizeName: 'Portrait',
            style: 'Wildlife'
        },
        {
            img: img4,
            prompt: 'A tranquil sunset over a rolling meadow',
            size: '16:9',
            sizeName: 'Widescreen',
            style: 'Sticker'
        },
        {
            img: img5,
            prompt: 'A cat looking out the window',
            size: '3:4',
            sizeName: 'Portrait',
            style: 'Default'
        },
        {
            img: img6,
            prompt: 'Serene sunsets over rolling hills and tranquil lakes',
            size: '16:9',
            sizeName: 'Widescreen',
            style: 'Default'
        },
        {
            img: img7,
            prompt: 'Abstract shapes in vibrant colors spiraling into an infinite abyss',
            size: '3:4',
            sizeName: 'Portrait',
            style: 'Oil'
        },
        {
            img: img8,
            prompt: 'Enchanted Forest at Twilight',
            size: '1:1',
            sizeName: 'Square',
            style: 'Wildlife'
        },
        {
            img: img9,
            prompt: 'Vibrant autumn leaves swirling around an old wooden bench',
            size: '1:1',
            sizeName: 'Square',
            style: 'Default'
        },
    ];

    const scrollToTopAndFocusInput = () => {
        const inputGenElement = document.getElementById('inputGen');
        if (inputGenElement) {
            const inputGenOffset = inputGenElement.offsetTop;

            window.scrollTo({
                top: inputGenOffset - window.innerHeight / 2,
                behavior: 'smooth',
            });

            setTimeout(() => {
                const inputElement = inputGenElement.querySelector('input');
                if (inputElement) {
                inputElement.focus();
                }
            }, 800);
        }
    }
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoadin(true);
        
        try {
            const response = await fetch('https://api.betterartist.ai/api/core/user/reset-password-confirm/' + token, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
            });
            if (response.ok) {
                const data = await response.json();
                console.log(data);
                setResetPasswordModal(false); 
                setResetPasswordModalContent(false);
                navigate(`/`)
                setAuth(1);
                setLoadin(false);
            } else {
                setError(true);
                setLoadin(false);
            }
        } catch (error) {
            console.error('Помилка при виконанні запиту:', error);
        }
    };

    return (
        <>
            {resetPasswordModal &&
                <div className="w-full h-screen fixed bg-[#191F28]/60 top-0 left-0 flex justify-center items-center z-[100]">
                    <div className="p-4 bg-[#1E293B] w-full md:max-w-[440px] h-screen md:h-auto md:p-10 md:rounded-[14px]">
                        <form onSubmit={handleSubmit} className="flex flex-col justify-between items-start" style={{'height': '-webkit-fill-available'}}>
                            <div className="w-full">
                                <div className="flex w-full justify-start items-center gap-4 text-white md:flex-row-reverse md:justify-between">
                                    <button className="text-[24px]" onClick={() => {setResetPasswordModal(false); setResetPasswordModalContent(false);}}>
                                        <IoMdClose />
                                    </button>
                                    <div className="font-extrabold text-[28px]">
                                        Reset Your Password
                                    </div>
                                </div>
                            
                                {resetPasswordModalContent ?
                                    <>
                                        <div className="w-full bg-[#0F172A] rounded-lg relative input-style mt-6">
                                            <input 
                                                className={`rounded-lg w-full bg-transparent px-[16px] relative z-10 text-[16px] leading-6 text-white ${formData.password1 ? 'pt-6 pb-2' : 'pt-4 pb-4'}`}
                                                style={{'borderColor': error && '#F87171'}} 
                                                type={oldShowPassword ? 'text' : 'password'} 
                                                name="password1" 
                                                id="password1"
                                                value={formData.password1} 
                                                onChange={handleChange} required 
                                            />
                                            <span className={`absolute z-0 left-4 text-[#94A3B8] block ${formData.password1 ? 'text-[12px] top-2' : 'text-[14px] top-4'}`}>Current Password</span>
                                            <div 
                                                className="absolute right-4 top-4 z-20 text-[#94A3B8] text-[26px] h-[26px] w-[26px] flex justify-center items-center cursor-pointer"
                                                onClick={() => {setOldShowPassword(!oldShowPassword)}} 
                                            >
                                                {oldShowPassword ?
                                                    <AiOutlineEye />
                                                    :
                                                    <AiOutlineEyeInvisible />
                                                }

                                            </div>
                                        </div>
                                        <div className="w-full bg-[#0F172A] rounded-lg relative input-style mt-2">
                                            <input 
                                                className={`rounded-lg w-full bg-transparent px-[16px] relative z-10 text-[16px] leading-6 text-white ${formData.password2 ? 'pt-6 pb-2' : 'pt-4 pb-4'}`}
                                                style={{'borderColor': error && '#F87171'}} 
                                                type={showPassword ? 'text' : 'password'} 
                                                name="password2" 
                                                id="password2"
                                                value={formData.password2} 
                                                onChange={handleChange} required 
                                            />
                                            <span className={`absolute z-0 left-4 text-[#94A3B8] block ${formData.password2 ? 'text-[12px] top-2' : 'text-[14px] top-4'}`}>New Password</span>
                                            <div 
                                                className="absolute right-4 top-4 z-20 text-[#94A3B8] text-[26px] h-[26px] w-[26px] flex justify-center items-center cursor-pointer"
                                                onClick={() => {setShowPassword(!showPassword)}} 
                                            >
                                                {showPassword ?
                                                    <AiOutlineEye />
                                                    :
                                                    <AiOutlineEyeInvisible />
                                                }

                                            </div>
                                        </div>
                                        
                                        {error &&
                                            <div className="text-[14px] text-red-400 font-normal">
                                                Something Wrong, Try again.
                                            </div>
                                        }
                                        
                                        <button type="submit" disabled={isButtonDisabled || loading} className={`mt-4 w-full text-[14px] leading-[28px] py-2 px-4 bg-green-300 rounded-[8px] text-gray-900 font-semibold ${isButtonDisabled && 'opacity-30 cursor-not-allowed'}`}>
                                            {loading ? 
                                                <div className="flex justify-center items-center">
                                                    <div className="w-[28px] h-[28px] rounded-full animate-spin border-2 border-solid border-gray-900 border-t-transparent"></div>
                                                </div>
                                                :
                                                'Reset Password'
                                            }
                                        </button>
                                    </>
                                    :
                                    <div className="flex justify-center items-center mt-6 md:mt-10">
                                        <div className="w-[28px] h-[28px] rounded-full animate-spin border-2 border-solid border-[#F8FAFC] border-t-transparent"></div>
                                    </div>
                                }
                            </div>
                        </form>

                    </div>
                </div>
            }
            <div className="w-full max-w-[1440px] m-auto md:px-[24px]">

                <div className="flex flex-col justify-center items-center p-4 gap-4">
                    <div className="text-center text-[28px] leading-[34px] font-bold md:text-[60px] md:leading-[72px]">
                        <p className="text-[#ffffff]">A picture is worth</p>
                        <p className="text-[#ffffff] line-through opacity-25">a thousand words</p>
                        <p className="text-[#6EE7B7]">your words</p>
                    </div>
                    <div className="text-center text-[16px] leading-[24px] text-[#F8FAFC] font-semibold md:text-[28px] md:leading-[34px] max-w-[800px] m-auto">
                        All it takes is some words and your imagination to create unique images.
                    </div>
                    <Input />
                    <div className="mt-4 text-center text-[23px] leading-[28px] text-[#F8FAFC] font-bold md:text-[34px] md:leading-[40px] max-w-[800px] m-auto md:mt-[64px]">
                        Art that inspires
                    </div>
                </div>

                <div className="pl-4 pr-0 md:pr-4">
                    <div className="hidden md:flex gap-10 mt-10">
                        {[0, 1, 2].map((columnIndex) => (

                            <div key={columnIndex} className="w-1/3 flex-1">

                            {exploreData
                            .filter((_, index) => (index % 3) === columnIndex)
                            .map((img, index) => (
                                <div key={index} className="flex-1 bg-gray-800/50 rounded-2xl w-full overflow-hidden mb-10 relative">
                                    <img src={img.img} alt="" />
                                </div>
                            ))}

                            </div>

                        ))}
                    </div>

                    <div className="flex md:hidden gap-2 w-full overflow-y-auto"> 
                        <div className="flex gap-2 pr-4">
                            {exploreData.map((img, index) => (
                                <div key={index} className="flex-1 bg-gray-800/50 rounded-2xl w-[40vw] h-[40vw] overflow-hidden relative justify-center items-center">
                                    <img src={img.img} alt="" className="min-h-full min-w-full" />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="flex flex-col justify-center items-center p-4 gap-4">
                    <div className="mt-4 flex flex-col gap-4 md:flex-row md:justify-between text-center text-[23px] leading-[28px] text-[#F8FAFC] font-bold md:text-[34px] md:leading-[40px] w-full mx-auto md:mt-[64px]">
                        <div className="max-w-[280px] mx-auto md:mx-[0] md:max-w-[100%]">
                            Tips for writing good prompts.
                        </div>
                        <button
                            onClick={scrollToTopAndFocusInput}
                            className={`flex gap-2 items-center justify-center px-5 bg-[#6EE7B7] border border-[#6EE7B7] hover:bg-[#10B981] hover:border-[#10B981] transition-all rounded-lg h-[40px] text-[#0F172A] text-[14px] md:text-[18px] font-semibold md:h-[50px]`}
                        >
                            Get Started
                        </button>
                    </div>

                    <div className="flex flex-col w-full gap-[16px] md:mt-[24px] md:mb-[64px]">

                        <div className="w-full p-4 border border-gray-700 rounded-2xl flex flex-col justify-between items-end gap-4 md:flex-row">
                            <div>
                                <div className="text-[16px] font-normal leading-[24px] text-gray-50 md:text-[20px] md:leading-[28px]">
                                    [Cyberpunk] [1:1]
                                </div>
                                <div className="text-[18px] font-semibold leading-[22px] text-gray-50 mt-2 md:text-[23px] md:font-bold">
                                    Mystical moonlit forest with shimmering lake 
                                </div>
                            </div>

                            <div className="max-md:w-full">
                                <button 
                                    onClick={() => {scrollToTopAndFocusInput(); setGenStyle('cyberpunk'); setGenSize('1:1'); setGenPromp('Mystical moonlit forest with shimmering lake');}}
                                    className="border rounded-[5px] text-[12px] w-full leading-[18px] font-bold border-gray-50 text-gray-50 bg-transparent p-0 hover:text-[#0F172A] hover:bg-[#F8FAFC] transition-all"
                                >
                                    <div className="px-3 py-2">Try Prompt</div>
                                </button>
                            </div>
                        </div>

                        <div className="w-full p-4 border border-gray-700 rounded-2xl flex flex-col justify-between items-end gap-4 md:flex-row">
                            <div>
                                <div className="text-[16px] font-normal leading-[24px] text-gray-50 md:text-[20px] md:leading-[28px]">
                                    [Oil] [16:9]
                                </div>
                                <div className="text-[18px] font-semibold leading-[22px] text-gray-50 mt-2 md:text-[23px] md:font-bold">
                                    City skyline at twilight with vibrant colors and reflection 
                                </div>
                            </div>

                            <div className="max-md:w-full">
                                <button 
                                    onClick={() => {scrollToTopAndFocusInput(); setGenStyle('oil'); setGenSize('16:9'); setGenPromp('City skyline at twilight with vibrant colors and reflection');}}
                                    className="border rounded-[5px] text-[12px] w-full leading-[18px] font-bold border-gray-50 text-gray-50 bg-transparent p-0 hover:text-[#0F172A] hover:bg-[#F8FAFC] transition-all"
                                >
                                    <div className="px-3 py-2">Try Prompt</div>
                                </button>
                            </div>
                        </div>

                        <div className="w-full p-4 border border-gray-700 rounded-2xl flex flex-col justify-between items-end gap-4 md:flex-row">
                            <div>
                                <div className="text-[16px] font-normal leading-[24px] text-gray-50 md:text-[20px] md:leading-[28px]">
                                    [Wildlife] [3:4]
                                </div>
                                <div className="text-[18px] font-semibold leading-[22px] text-gray-50 mt-2 md:text-[23px] md:font-bold">
                                Majestic aurora illuminates snowy mountain peak at twilight
                                </div>
                            </div>

                            <div className="max-md:w-full">
                                <button 
                                    onClick={() => {scrollToTopAndFocusInput(); setGenStyle('wildlife'); setGenSize('3:4'); setGenPromp('Majestic aurora illuminates snowy mountain peak at twilight');}}
                                    className="border rounded-[5px] text-[12px] w-full leading-[18px] font-bold border-gray-50 text-gray-50 bg-transparent p-0 hover:text-[#0F172A] hover:bg-[#F8FAFC] transition-all"
                                >
                                    <div className="px-3 py-2">Try Prompt</div>
                                </button>
                            </div>
                        </div>

                    </div>

                </div>


                <div className="flex flex-col gap-4 max-md:gap-0 md:pb-4">
                    <div className="w-full rounded-2xl bg-green-300 p-16 flex flex-col justify-between items-center gap-10 max-md:rounded-[0px] max-md:p-4">
                        <div className="text-[34px] font-bold leading-[40px] text-gray-900 max-md:text-center max-md:text-[23px] max-md:leading-[28px] max-md:max-w-[230px] max-md:m-auto">Enter a prompt. Generate images. It’s that simple.</div>

                        <button onClick={scrollToTopAndFocusInput} className="text-[18px] font-semibold leading-[22px] text-gray-50 px-5 py-[14px] border-gray-900 border rounded-[5px] bg-gray-900 hover:border-[#334155] hover:bg-[#334155] max-md:w-full">
                            Get Started
                        </button>
                    </div>
                    
                    <div className="w-full mt-4 rounded-2xl bg-gray-700 p-16 flex flex-col justify-between items-center gap-10 max-md:mt-0 max-md:rounded-[0px] max-md:px-6 max-md:py-4 max-md:gap-6">
                        <div className="w-full flex justify-between items-start max-md:flex-col">
                            <div>
                                <Link to={`/`} className="text-[23px] font-extrabold uppercase text-gray-50">
                                    Revel
                                    <span className="text-green-300">8</span>
                                </Link>
                            </div>
                            <div className="flex justify-center items-start gap-20 max-md:flex-col max-md:gap-6">
                                <div className="flex flex-col gap-6 max-md:flex-row max-md:mt-6">
                                    <div className="text-[14px] font-semibold leading-[17px] text-gray-50">Terms & Conditions</div>
                                    <div className="text-[14px] font-semibold leading-[17px] text-gray-50">Privacy Policy</div>
                                </div>
                                {!userAccess && 
                                    <div className="flex gap-2">
                                        <button 
                                            className="border rounded-[5px] leading-[18px] font-semibold border-gray-50 text-gray-50 bg-transparent hover:text-[#0F172A] hover:bg-[#F8FAFC] transition-all p-0" 
                                            onClick={()=> {setAuth(1)}}
                                        >
                                            <div  className="px-3 py-2">Log in</div>
                                        </button>
                                        <button 
                                            className="bg-gray-50 rounded-[5px] font-semibold leading-[18px] text-primary-foreground hover:bg-gray-300 text-gray-900" 
                                            onClick={()=> {setAuth(2)}}
                                        >
                                            <div className="px-3 py-2">Sign Up</div>
                                        </button>
                                    </div>
                                }
                            </div>
                        </div>

                        <div className="w-full h-[1px] bg-gray-500"></div>

                        <div className="text-[14px] font-normal leading-[17px] text-gray-50 max-md:text-center">
                            Copyright © 2023 | AI Image Generator | All Rights Reserved
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
 
export default Landing;