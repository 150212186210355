import { useState } from "react";
import Cookies from 'js-cookie';

import { IoClose } from 'react-icons/io5';
import { Link } from "react-router-dom";
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { useUser } from "../../provider/UserProvider";

const PasswordChange = ({setPasswordChange, tab, setTab}) => {
    const { userAccess, userData } = useUser();
    const [error, setError] = useState(false);
    const [loading, setLoadin] = useState(false);
    const [password, setPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [oldShowPassword, setOldShowPassword] = useState(false);

    const [formData, setFormData] = useState({
        new_password: '',
        old_password: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        setError(false);
    };
    
    const isButtonDisabled = !formData.new_password || !formData.old_password;

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoadin(true);
        
        try {
            const response = await fetch('https://api.betterartist.ai/api/core/user/change_password', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userAccess}`,
            },
            body: JSON.stringify(formData),
            });
            if (response.ok) {
                setPasswordChange(false)
            } else {
                setError(true);
                setPasswordChange(false)
            }
        } catch (error) {
            console.error('Помилка при виконанні запиту:', error);
            setPasswordChange(false)
        }
    };


    return (
        <div className="fixed top-0 left-0 w-full h-full bg-[#191F28]/60 z-50 flex justify-center items-center">
            <div className='bg-gray-800 rounded-[14px] w-[440px] max-lg:h-full max-lg:rounded-[0]'>
                <div className='w-full flex justify-between items-start p-10 max-lg:p-4 flex-row'>
                    <div className="block md:hidden w-[28px]"></div>
                    <div className='text-gray-50 font-extrabold text-[28px] leading-[34px] max-lg:text-[23px] max-lg:leading-[28px]'>
                        Account Settings
                    </div>
                    <button onClick={() => {setPasswordChange(false)}} className='text-gray-400 text-[34px] cursor-pointer max-lg:text-[28px]'>
                        <IoClose />
                    </button>
                </div>

                <div className="w-full flex">
                    <div onClick={() => setTab(false)} className={`cursor-pointer w-1/2 flex-1 p-4 pt-0 flex justify-center items-center text-[16px] leading-[24px] font-semibold max-lg:text-[14px] max-lg:leading-[17px] ${!tab ? 'text-green-300 border-b-2 border-green-300': 'text-gray-50 border-b border-[#334155]'}`}>
                        Account Information
                    </div>
                    <div onClick={() => setTab(true)} className={`cursor-pointer w-1/2 flex-1 p-4 pt-0 flex justify-center items-center text-[16px] leading-[24px] font-semibold max-lg:leading-[17px] ${tab ? 'text-green-300 border-b-2 border-green-300': 'text-gray-50 border-b border-[#334155]'}`}>
                        Manage Billing
                    </div>
                </div>

                <div className="p-10 max-lg:p-4">
                    {!tab ?
                        <div className="w-full">
                            {password ?
                                <div>   
                                    <form onSubmit={handleSubmit} className='flex flex-col gap-[8px]'>
                                        <div className="w-full bg-[#0F172A] rounded-lg relative input-style">
                                            <input 
                                                className={`rounded-lg w-full bg-transparent px-[16px] relative z-10 text-[16px] leading-6 text-white ${formData.old_password ? 'pt-6 pb-2' : 'pt-4 pb-4'}`}
                                                style={{'borderColor': error && '#F87171'}} 
                                                type={oldShowPassword ? 'text' : 'password'} 
                                                name="old_password" 
                                                id="old_password"
                                                value={formData.old_password} 
                                                onChange={handleChange} required 
                                            />
                                            <span className={`absolute z-0 left-4 text-[#94A3B8] block ${formData.old_password ? 'text-[12px] top-2' : 'text-[14px] top-4'}`}>Current Password</span>
                                            <div 
                                                className="absolute right-4 top-4 z-20 text-[#94A3B8] text-[26px] h-[26px] w-[26px] flex justify-center items-center cursor-pointer"
                                                onClick={() => {setOldShowPassword(!oldShowPassword)}} 
                                            >
                                                {oldShowPassword ?
                                                    <AiOutlineEye />
                                                    :
                                                    <AiOutlineEyeInvisible />
                                                }

                                            </div>
                                        </div>
                                        <div className="w-full bg-[#0F172A] rounded-lg relative input-style">
                                            <input 
                                                className={`rounded-lg w-full bg-transparent px-[16px] relative z-10 text-[16px] leading-6 text-white ${formData.new_password ? 'pt-6 pb-2' : 'pt-4 pb-4'}`}
                                                style={{'borderColor': error && '#F87171'}} 
                                                type={showPassword ? 'text' : 'password'} 
                                                name="new_password" 
                                                id="new_password"
                                                value={formData.new_password} 
                                                onChange={handleChange} required 
                                            />
                                            <span className={`absolute z-0 left-4 text-[#94A3B8] block ${formData.new_password ? 'text-[12px] top-2' : 'text-[14px] top-4'}`}>New Password</span>
                                            <div 
                                                className="absolute right-4 top-4 z-20 text-[#94A3B8] text-[26px] h-[26px] w-[26px] flex justify-center items-center cursor-pointer"
                                                onClick={() => {setShowPassword(!showPassword)}} 
                                            >
                                                {showPassword ?
                                                    <AiOutlineEye />
                                                    :
                                                    <AiOutlineEyeInvisible />
                                                }

                                            </div>
                                        </div>
                                        {error &&
                                            <div className="text-[14px] text-red-400 font-normal">
                                                Incorrect password
                                            </div>
                                        }
                                        <button type="submit" disabled={isButtonDisabled || loading} className={`mt-4 text-[14px] leading-[28px] py-2 px-4 bg-green-300 rounded-[8px] text-gray-900 font-semibold ${isButtonDisabled && 'opacity-30 cursor-not-allowed'}`}>
                                            {loading ? 
                                                <div className="flex justify-center items-center">
                                                    <div className="w-[28px] h-[28px] rounded-full animate-spin border-2 border-solid border-gray-900 border-t-transparent"></div>
                                                </div>
                                                :
                                                'Save'
                                            }
                                        </button>
                                    </form>
                                </div>
                                :
                                <>
                                    <div className="relative w-full">
                                        <div className="absolute top-2 left-4 text-[12px] leading-[18px] text-gray-400">Email address</div>
                                        <input
                                            type="text"
                                            disabled={true}
                                            value={userData.email}
                                            className='px-4 pb-2 pt-6 rounded-[8px] text-[14px] placeholder:text-gray-400 bg-gray-900 text-gray-200 w-full'
                                        />
                                    </div>
        
                                    <div className="w-full flex justify-between items-center mt-4 mb-3">
                                        <div></div>
                                        <div onClick={() => setPassword(true)} className="text-green-300 text-[14px] leading-[17px] font-semibold cursor-pointer">Change</div>
                                    </div>
        
                                    <div className="relative w-full">
                                        <div className="absolute top-2 left-4 text-[12px] leading-[18px] text-gray-400">Password</div>
                                        <input
                                            type="text"
                                            disabled={true}
                                            value='**********'
                                            className='px-4 pb-2 pt-6 rounded-[8px] text-[14px] placeholder:text-gray-400 bg-gray-900 text-gray-200 w-full'
                                        />
                                    </div>
                                </>
                            }

                        </div>
                        :
                        <div className="w-full flex flex-col gap-4">
                           <div className="text-[14px] text-gray-50 leading-[17px] flex gap-4 items-center font-bold">
                                Current Plan
                                <div className="text-green-300 text-[12px] font-semibold flex gap-1 items-center justify-center border rounded-full border-green-300 px-2 py-[2px]">
                                    <div className="w-[6px] h-[6px] rounded-full bg-green-300"></div>
                                    Active
                                </div>
                            </div> 

                            <div className="bg-gray-900 rounded-lg p-4 flex justify-between items-center gap-4 max-lg:flex-col">
                                <div className="flex flex-col gap-2">
                                    <div className="text-[14px] leading-[18px] text-gray-50 font-semibold capitalize">{userData.plan}</div>
                                    <div className="text-[34px] leading-[40px] text-gray-50 font-bold">
                                        ${userData.plan === 'free' ? (
                                            '0'
                                        ) : userData.plan === 'standard' ? (
                                            '5'
                                        ) : userData.plan === 'advanced' ? (
                                            '15'
                                        ) : userData.plan === 'professional' ? (
                                            '25'
                                        ) : null
                                        }

                                    </div>
                                </div>

                                <div className="flex max-lg:w-full">
                                    <Link onClick={() => {setPasswordChange(false); Cookies.set('backUrl', document.URL)}} to={`/upgrade`} className="px-5 p-[14px] bg-green-300 hover:bg-[#10B981] transition-all rounded-[5px] text-gray-900 text-[18px] leading-[24px] font-semibold max-lg:w-full max-lg:justify-center max-lg:text-center">
                                        Upgrade
                                    </Link>
                                </div>
                            </div>
                        </div>
                    }
                </div>

            </div>
        </div>
    );
    
}
 
export default PasswordChange;