import InputDashboard from "../../component/ui/InputDashboard";
import None from "../../assets/image/styleImg/None.png";
import Oil from "../../assets/image/styleImg/Oil.png";
import Drawing from "../../assets/image/styleImg/Drawing.png";
import Cyberpunk from "../../assets/image/styleImg/Cyberpunk.png";
import Wildlife from "../../assets/image/styleImg/Wildlife.png";
import { useGen } from "../../provider/genProvider";
import SameImage from "./SameImage";

const SamePage = () => {
    const { genStyle, setGenStyle, genSize, setGenSize } = useGen();
    
    const ratio = [
        {
            name: 'Landscape',
            size: '4:3',
            width: '16',
            height: '12'
        },
        {
            name: 'Portrait',
            size: '3:4',
            width: '12',
            height: '16'
        },
        {
            name: 'Square',
            size: '1:1',
            width: '16',
            height: '16'
        },
        {
            name: 'Widescreen',
            size: '16:9',
            width: '16',
            height: '9'
        }
    ]

    const style = [
        {
            name: 'none',
            img: None
        },
        {
            name: 'oil',
            img: Oil
        },
        {
            name: 'drawing',
            img: Drawing
        },
        {
            name: 'cyberpunk',
            img: Cyberpunk
        },
        {
            name: 'wildlife',
            img: Wildlife
        }, 
    ]

    const hendleRatio = (rt) => {
        setGenSize(rt.size);
    }
    
    const hendleStyle = (st) => {
        setGenStyle(st.name);
    }

    return (
        <div className="block w-full max-w-[1280px] px-0 md:px-[40px] mx-auto min-h-[calc(100vh-57px)] md:min-h-[calc(100vh-84px)] relative">
            <div className="flex w-full gap-0 md:gap-6 h-full">
                <div className="flex-auto w-full md:w-2/3 py-4 h-full px-4">
                    <SameImage />
                </div>
                <div className="flex-auto hidden w-full md:w-1/3 md:flex py-4">
                    <div className="bg-[rgba(51,65,85,0.50)] w-full h-full rounded-2xl py-6 px-4">
                        <div className="flex flex-col gap-6 h-auto">
                            <h4 className="text-gray-50 font-bold text-[19px] leading-[23px]">Aspect Ratio</h4>

                            <div className="flex flex-col gap-4">
                                {ratio.map((rt, index) => (
                                    <div key={index} className={`flex gap-4 items-center justify-between p-2 cursor-pointer rounded-lg ${genSize === rt.size ? 'bg-[#334155]' : "bg-transparent hover:bg-[#475569]"}`} onClick={() => hendleRatio(rt)}>
                                        <div className="flex gap-4 items-center">
                                            <div className="w-[28px] h-[28px] flex items-center justify-end mr-[10px]">
                                                <div className={`rounded-sm ${genSize === rt.size ? 'bg-green-300' : "bg-gray-400"}`} style={{height: `${rt.height}px`, width: `${rt.width}px`}}></div>
                                            </div>
                                            <div className="text-[18px] leading-[22px] font-normal text-gray-50">{rt.name}</div>
                                            <div className={`px-3 py-1 rounded-full border border-gray-700 text-[14px] leading-[17px] text-gray-50 font-semibold ${genSize === rt.size ? 'bg-gray-700' : "bg-transparent"}`}>{rt.size}</div>
                                        </div>
                                        {genSize === rt.size && 
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                <path d="M18 9C18 11.3869 17.0518 13.6761 15.364 15.364C13.6761 17.0518 11.3869 18 9 18C6.61305 18 4.32387 17.0518 2.63604 15.364C0.948212 13.6761 0 11.3869 0 9C0 6.61305 0.948212 4.32387 2.63604 2.63604C4.32387 0.948212 6.61305 0 9 0C11.3869 0 13.6761 0.948212 15.364 2.63604C17.0518 4.32387 18 6.61305 18 9ZM13.5337 5.59125C13.4534 5.51117 13.3577 5.44812 13.2524 5.40587C13.1471 5.36361 13.0344 5.34303 12.921 5.34534C12.8076 5.34765 12.6958 5.37281 12.5923 5.41931C12.4888 5.46582 12.3958 5.53271 12.3187 5.616L8.41162 10.5941L6.057 8.23837C5.89705 8.08933 5.6855 8.0082 5.46691 8.01205C5.24832 8.01591 5.03976 8.10446 4.88518 8.25905C4.73059 8.41364 4.64204 8.6222 4.63818 8.84079C4.63432 9.05938 4.71546 9.27093 4.8645 9.43088L7.84125 12.4087C7.92144 12.4888 8.01693 12.5519 8.12203 12.5942C8.22713 12.6366 8.33968 12.6573 8.45296 12.6552C8.56625 12.6531 8.67795 12.6282 8.78141 12.582C8.88486 12.5358 8.97795 12.4692 9.05512 12.3863L13.5461 6.7725C13.6992 6.61331 13.7838 6.40043 13.7817 6.17958C13.7796 5.95872 13.691 5.74749 13.5349 5.59125H13.5337Z" fill="#6EE7B7"/>
                                            </svg>
                                        }
                                    </div>
                                ))}
                            </div>
                            <div className="w-full h-[1px] bg-gray-600"></div>
                            </div>

                            <div className="flex flex-col gap-6 overflow-y-auto scroll-small mt-6">
                            <h4 className="text-gray-50 font-bold text-[19px] leading-[23px]">Style</h4>

                            <div className="flex flex-col gap-2">
                                {style.map((st, index) => (
                                    <div key={index} className={`flex gap-4 justify-between items-center p-2 cursor-pointer rounded-lg ${genStyle === st.name ? 'bg-[#334155]' : "bg-transparent hover:bg-[#475569]"}`} onClick={() => hendleStyle(st)}>
                                        <div className="flex gap-4 items-center">
                                            <div className="w-[40px] h-[40px] flex items-center justify-end mr-[10px]">
                                                <img src={st.img} className="rounded-sm" alt="" />
                                            </div>
                                            <div className={`text-[18px] leading-[22px] font-normal capitalize ${genStyle === st.name ? 'text-green-300' : 'text-gray-50'}`}>{st.name}</div>
                                        </div>
                                        {genStyle === st.name && 
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                <path d="M18 9C18 11.3869 17.0518 13.6761 15.364 15.364C13.6761 17.0518 11.3869 18 9 18C6.61305 18 4.32387 17.0518 2.63604 15.364C0.948212 13.6761 0 11.3869 0 9C0 6.61305 0.948212 4.32387 2.63604 2.63604C4.32387 0.948212 6.61305 0 9 0C11.3869 0 13.6761 0.948212 15.364 2.63604C17.0518 4.32387 18 6.61305 18 9ZM13.5337 5.59125C13.4534 5.51117 13.3577 5.44812 13.2524 5.40587C13.1471 5.36361 13.0344 5.34303 12.921 5.34534C12.8076 5.34765 12.6958 5.37281 12.5923 5.41931C12.4888 5.46582 12.3958 5.53271 12.3187 5.616L8.41162 10.5941L6.057 8.23837C5.89705 8.08933 5.6855 8.0082 5.46691 8.01205C5.24832 8.01591 5.03976 8.10446 4.88518 8.25905C4.73059 8.41364 4.64204 8.6222 4.63818 8.84079C4.63432 9.05938 4.71546 9.27093 4.8645 9.43088L7.84125 12.4087C7.92144 12.4888 8.01693 12.5519 8.12203 12.5942C8.22713 12.6366 8.33968 12.6573 8.45296 12.6552C8.56625 12.6531 8.67795 12.6282 8.78141 12.582C8.88486 12.5358 8.97795 12.4692 9.05512 12.3863L13.5461 6.7725C13.6992 6.61331 13.7838 6.40043 13.7817 6.17958C13.7796 5.95872 13.691 5.74749 13.5349 5.59125H13.5337Z" fill="#6EE7B7"/>
                                            </svg>
                                        }
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fixed md:absolute bottom-0 md:bottom-4 left-0 md:left-6 w-full z-[100] md:w-[calc((100%-92px)/3*2)]">
                <InputDashboard />
            </div>
        </div>
    );
}
 
export default SamePage;